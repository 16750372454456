import warning from "../images/icons/warningRed.svg";

import { useSelector } from "react-redux";
import classNames from "classnames";
import { useMediaQuery } from "@uidotdev/usehooks";
import { language } from "../utils/constants";

export const SendingAllert = () => {
  const iphone = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 744px)"
  );

  const order = useSelector((state) => state.order);

  const langCode = useSelector((state) => state.language);

  return (
    <div
      className={classNames("bg-order rounded-3xl w-full", {
        "px-12 py-10": !iphone,
        "px-4 py-10": iphone,
      })}
    >
      {order && order.status === "NEW" && (
        <>
          <div
            className={classNames(
              "flex items-start text-[32px] font-bold mb-6",
              {
                "text-xl": iphone,
              }
            )}
          >
            <img
              className="mr-2 w-10 h-10 self-baseline"
              src={warning}
              alt="warning"
            />
            <p className="text-left text-2xl font-semibold">
              {language[langCode].sendingPage.sendingAllert.wait}
            </p>
          </div>
          <div className="flex flex-col items-start text-left text-base font-normal">
            {language[langCode].sendingPage.sendingAllert.address}
          </div>
        </>
      )}
      {order && order.status === "EMERGENCY" && (
        <>
          <div
            className={classNames(
              "flex items-start text-[32px] font-bold mb-6",
              {
                "text-xl": iphone,
              }
            )}
          >
            <img
              className="mr-2 w-10 h-10 self-baseline"
              src={warning}
              alt="warning"
            />
            <p className="text-left text-2xl font-semibold">
              {language[langCode].sendingPage.sendingAllert.status}
            </p>
          </div>
          <div className="flex flex-col items-start text-left text-base font-normal">
            {language[langCode].sendingPage.sendingAllert.goTo}{" "}
            {order &&
              order.emergency.choice === "EXCHANGE" &&
              language[langCode].sendingPage.sendingAllert.exchange}{" "}
            {order &&
              order.emergency.choice === "REFUND" &&
              language[langCode].sendingPage.sendingAllert.refund}{" "}
            {language[langCode].sendingPage.sendingAllert.funds}
          </div>
        </>
      )}
      {order && order.status === "WITHDRAW" && (
        <>
          <div
            className={classNames(
              "flex items-start text-[32px] font-bold mb-6",
              {
                "text-xl": iphone,
              }
            )}
          >
            <img
              className="mr-2 w-10 h-10 self-baseline"
              src={warning}
              alt="warning"
            />
            <p className="text-left text-2xl font-semibold">
              {language[langCode].sendingPage.sendingAllert.soon}
            </p>
          </div>
          <div className="flex flex-col items-start text-left text-base font-normal">
            {language[langCode].sendingPage.sendingAllert.arrived}{" "}
            {
              <span className="font-semibold text-blue-200">
                {order.back.address}
              </span>
            }
          </div>
        </>
      )}
      {order && (order.status === "PENDING" || order.status === "EXCHANGE") && (
        <>
          <div
            className={classNames(
              "flex items-start text-[32px] font-bold mb-6",
              {
                "text-xl": iphone,
              }
            )}
          >
            <img
              className="mr-2 w-10 h-10 self-baseline"
              src={warning}
              alt="warning"
            />
            <p className="text-left text-2xl font-semibold">
              {language[langCode].sendingPage.sendingAllert.orderSoon}
            </p>
          </div>
          <div className="flex flex-col items-start text-left text-base font-normal">
            {language[langCode].sendingPage.sendingAllert.exchangeSoon}
          </div>
        </>
      )}
      {order &&
        order.status === "EXPIRED" &&
        order.emergency.choice === "EXCHANGE" && (
          <>
            <div
              className={classNames(
                "flex items-start text-[32px] font-bold mb-6",
                {
                  "text-xl": iphone,
                }
              )}
            >
              <img
                className="mr-2 w-10 h-10 self-baseline"
                src={warning}
                alt="warning"
              />
              <p className="text-left text-2xl font-semibold">
                {language[langCode].sendingPage.sendingAllert.wait}
              </p>
            </div>
            <div className="flex flex-col items-start text-left text-base font-normal">
              {language[langCode].sendingPage.sendingAllert.address}
            </div>
          </>
        )}
      {order &&
        order.status === "EXPIRED" &&
        order.emergency.choice === "REFUND" && (
          <>
            <div
              className={classNames(
                "flex items-start text-[32px] font-bold mb-6",
                {
                  "text-xl": iphone,
                }
              )}
            >
              <img
                className="mr-2 w-10 h-10 self-baseline"
                src={warning}
                alt="warning"
              />
              <p className="text-left text-2xl font-semibold">
                {language[langCode].sendingPage.sendingAllert.wait}
              </p>
            </div>
            <div className="flex flex-col items-start text-left text-base font-normal">
              {language[langCode].sendingPage.sendingAllert.addressWait}{" "}
              {
                <span className="flex flex-col font-semibold text-blue-200">
                  <span>{order.back.address}</span>
                  {order.back.tag && (
                    <span>
                      <span className="font-normal text-white">
                        {order.back.tagName ? order.back.tagName : "MEMO"}:{" "}
                      </span>
                      {order.back.tag}
                    </span>
                  )}
                </span>
              }
            </div>
          </>
        )}
    </div>
  );
};
