import "@splidejs/react-splide/css/core";
import { useMediaQuery } from "@uidotdev/usehooks";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { trimNumberCoin } from "../utils/helpers";

import arrow from "../images/pink-arrow.svg";
import { language } from "../utils/constants";

export const SendingCoinTo = () => {
  const iphone = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 600px)"
  );
  const macbook = useMediaQuery(
    "only screen and (min-width : 1024px) and (max-width : 1440px)"
  );
  const desctop = useMediaQuery("only screen and (min-width : 1440px)");
  const miniSending = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 1024px)"
  );

  const order = useSelector((state) => state.order);
  const coins = useSelector((state) => state.coins);
  const coinFrom = coins.find((coin) => coin.code === order.from.code);
  const coinTo = coins.find((coin) => coin.code === order.to.code);

  const langCode = useSelector((state) => state.language);

  return (
    <div
      className={classNames("flex mt-6", {
        "justify-center": desctop || macbook,
        "flex-col self-start justify-left ml-6 relative":
          miniSending && !iphone,
        "flex-row mx-auto space-x-4": !miniSending,
        "flex-col justify-left ml-2 relative": iphone,
      })}
    >
      <div
        className={classNames("flex flex-row", {
          "w-[45%]": desctop || macbook,
          "mb-4": miniSending,
          "space-x-4": !miniSending,
        })}
      >
        <div
          className={classNames("flex flex-col flex-grow", {
            "order-2 text-left": miniSending,
            "order-1 text-right space-y-1": !miniSending,
          })}
        >
          <p className="text-xl font-semibold">
            {language[langCode].sendingPage.sendingTo.sending}
          </p>
          <p className="text-xl font-semibold">
            <span className="font-mono text-2xl leading-10">
              {order && trimNumberCoin(order.from.amount)}
            </span>{" "}
            {order && order.from.code}
          </p>
          <p className="break-all text-base text-[#D7DFFF]">
            {order && order.from.address}
          </p>
        </div>
        <img
          className={classNames("w-20 h-20", {
            "w-20 h-20": !iphone,
            "w-8 h-8": iphone,
            "order-1 mr-4": miniSending,
            "order-2": !miniSending,
          })}
          src={coinFrom?.logo}
          alt={language[langCode].sendingPage.sendingTo.coinAlt}
        />
      </div>
      {!miniSending && (
        <img
          className="w-16 h-16"
          src={arrow}
          alt={language[langCode].sendingPage.sendingTo.arrowAlt}
        />
      )}
      <div
        className={classNames("flex flex-row", {
          "w-[45%]": desctop || macbook,
          "w-full": miniSending,
          "space-x-4": !miniSending,
        })}
      >
        <img
          className={classNames("", {
            "w-20 h-20": !iphone,
            "w-8 h-8": iphone,
            "mr-4": miniSending,
            "": !miniSending,
          })}
          src={coinTo?.logo}
          alt={language[langCode].sendingPage.sendingTo.coinAlt}
        />
        <div
          className={classNames("text-left flex flex-col", {
            "": miniSending,
            "space-y-1": !miniSending,
          })}
        >
          <p className="text-xl font-semibold">
            {language[langCode].sendingPage.sendingTo.recieve}
          </p>
          <p className="text-xl font-semibold">
            <span className="font-mono text-2xl leading-10">
              {order && trimNumberCoin(order.to.amount)}
            </span>{" "}
            {order && order.to.code}
          </p>
          <p className="break-all text-base text-[#D7DFFF]">
            {order && order.to.address}
          </p>
        </div>
      </div>
      {miniSending && !iphone && (
        <img
          className="w-16 h-16 absolute top-[20%] left-[500px] rotate-90"
          src={arrow}
          alt={language[langCode].sendingPage.sendingTo.arrowAlt}
        />
      )}
    </div>
  );
};
