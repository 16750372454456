import classNames from "classnames";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { formatSeconds, formatDate, handleClickCopy } from "../utils/helpers";

import squaresImg from "../images/icons/squares.svg";

import { language } from "../utils/constants";

export const SendingOrderNumber = () => {
  const [isIdCopied, setIdCopied] = useState(false);
  const order = useSelector((state) => state.order);
  const [timer, setTimer] = useState(null);
  const langCode = useSelector((state) => state.language);

  useEffect(() => {
    setTimer(order && order.time.left);
  }, [order]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
    return () => clearTimeout(timeout);
  });

  return (
    <div
      className={classNames(
        "flex flex-col bg-order px-6 py-8 rounded-3xl text-left space-y-4 min-w-[255px] flex-grow"
      )}
    >
      <p className="text-2xl font-semibold">
        {language[langCode].sendingPage.orderNumber.number}
      </p>
      <div
        className="flex flex-row items-center cursor-pointer"
        onClick={() => handleClickCopy(order.id, setIdCopied)}
      >
        <p
          className={classNames("text-3xl font-semibold mr-1", {
            "text-blue-200": !isIdCopied,
            "text-[#95FF54]": isIdCopied,
          })}
        >
          {order && order.id}
        </p>
        <img
          src={squaresImg}
          alt={language[langCode].sendingPage.orderNumber.iconAlt}
        />
      </div>
      {order && order.status === "NEW" && (
        <div>
          <p className="text-2xl font-semibold">
            {language[langCode].sendingPage.orderNumber.time}
          </p>
          <p className="text-5xl font-bold text-blue-200">
            {formatSeconds(order && timer)}
          </p>
        </div>
      )}
      {order &&
        (order.status === "PENDING" ||
          order.status === "EXCHANGE" ||
          order.status === "WITHDRAW") && (
          <div>
            <p className="text-2xl font-semibold">
              {language[langCode].sendingPage.orderNumber.status}
            </p>
            <p className="text-2xl font-bold text-blue-200">
              {language[langCode].sendingPage.orderNumber.earned}
            </p>
          </div>
        )}
      {order && order.status === "DONE" && (
        <div>
          <p className="text-2xl font-semibold">
            {language[langCode].sendingPage.orderNumber.status}
          </p>
          <p className="text-2xl font-bold text-lime-300">
            {order.emergency.choice === "REFUND"
              ? language[langCode].sendingPage.orderNumber.status
              : language[langCode].sendingPage.orderNumber.done}
          </p>
        </div>
      )}
      {order && order.status === "EXPIRED" && (
        <div>
          <p className="text-2xl font-semibold">
            {language[langCode].sendingPage.orderNumber.status}
          </p>
          <p className="text-2xl font-bold text-red-500">
            {language[langCode].sendingPage.orderNumber.orderDone}
          </p>
        </div>
      )}
      {order && order.status === "EMERGENCY" && (
        <div>
          <p className="text-2xl font-semibold">
            {language[langCode].sendingPage.orderNumber.status}
          </p>
          <p className="text-2xl font-bold text-red-500">
            {language[langCode].sendingPage.orderNumber.answer}
          </p>
        </div>
      )}
      <div>
        <p className="text-2xl font-semibold">
          {language[langCode].sendingPage.orderNumber.type}
        </p>
        <p className="text-2xl font-semibold text-blue-200">
          {order && order.type === "float"
            ? language[langCode].sendingPage.orderNumber.float
            : language[langCode].sendingPage.orderNumber.fixed}
        </p>
      </div>
      <div>
        <p className="text-2xl font-semibold">
          {language[langCode].sendingPage.orderNumber.timeCreated}
        </p>
        <p className="text-2xl font-semibold text-blue-200">
          {formatDate(order && order.time.reg)}
        </p>
      </div>
      {order &&
        (order.status === "PENDING" || order.status === "EMERGENCY") && (
          <div>
            <p className="text-2xl font-semibold">
              {language[langCode].sendingPage.orderNumber.timeRecieved}
            </p>
            <p className="text-2xl font-semibold text-blue-200">
              {formatDate(order && order.time.start)}
            </p>
          </div>
        )}
      {order && order.status === "DONE" && (
        <div>
          <p className="text-2xl font-semibold">
            {language[langCode].sendingPage.orderNumber.timeDone}
          </p>
          <p className="text-2xl font-semibold text-blue-200">
            {formatDate(order && order.time.finish)}
          </p>
        </div>
      )}
    </div>
  );
};
