// Components
import { FAQItem } from "../components/FAQItem";

// Libraries
import classNames from "classnames";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useState } from "react";
import { useSelector } from "react-redux";

import { language } from "../utils/constants";

export const Faq = () => {
  const iphone = useMediaQuery(
    "only screen and (min-width : 343px) and (max-width : 744px)"
  );
  const ipadMini = useMediaQuery(
    "only screen and (min-width : 744px) and (max-width : 1024px)"
  );
  const macbook = useMediaQuery(
    "only screen and (min-width : 1024px) and (max-width : 1328px)"
  );
  const desctop = useMediaQuery("only screen and (min-width : 1328px)");

  const [aboutCryptochillOpen, setAboutCryptochillOpen] = useState(true);
  const [aboutExchangeOpen, setAboutExchangeOpen] = useState(false);
  const [aboutOrderOpen, setAboutOrderOpen] = useState(false);

  const langCode = useSelector((state) => state.language);

  function handleOpen(setCurrentAbout) {
    [setAboutCryptochillOpen, setAboutExchangeOpen, setAboutOrderOpen].forEach(
      (setAbout) => setAbout(false)
    );
    setCurrentAbout(true);
  }

  return (
    <section
      className={classNames(
        "mx-auto max-w-1328 text-white font-semibold text-left",
        {
          "max-w-1328 min-h-section-page-desctop": desctop,
          "max-w-main-container min-h-section-page-desctop": macbook,
          "max-w-tablet-container": ipadMini,
          "max-w-mobile-container": iphone,
        }
      )}
    >
      <h3
        className={classNames(
          "inline-block font-bold text-transparent bg-gradient-to-r from-[#FBE3F1] to-[#CAAEFF] bg-clip-text mb-8 mt-10",
          {
            "text-3xl": iphone,
            "text-5xl": ipadMini,
            "text-5xl ": macbook,
            "text-5xl  ": desctop,
          }
        )}
      >
        {language[langCode].faqPage.title}
      </h3>

      {/* Навигация по разделам FAQ */}
      <ul className="flex items-center mb-16">
        <li className="mr-2">
          <button
            className={
              "text-2xl px-3 pt-1 pb-2 rounded-lg " +
              (aboutCryptochillOpen ? "bg-btns" : "")
            }
            onClick={() => handleOpen(setAboutCryptochillOpen)}
          >
            {`${language[langCode].faqPage.about} CoinCharger`}
          </button>
        </li>
        <li className="mr-2">
          <button
            className={
              "text-2xl px-3 pt-1 pb-2 rounded-lg " +
              (aboutExchangeOpen ? "bg-btns" : "")
            }
            onClick={() => handleOpen(setAboutExchangeOpen)}
          >
            {language[langCode].faqPage.aboutChange}
          </button>
        </li>
        <li className="mr-2">
          <button
            className={
              "text-2xl px-3 pt-1 pb-2 rounded-lg " +
              (aboutOrderOpen ? "bg-btns" : "")
            }
            onClick={() => handleOpen(setAboutOrderOpen)}
          >
            {language[langCode].faqPage.aboutOrder}
          </button>
        </li>
      </ul>

      {aboutCryptochillOpen && (
        <ul>
          <FAQItem
            question={language[langCode].faqPage.faqWhat.title}
            account="1"
          >
            <p>{language[langCode].faqPage.faqWhat.text}</p>
          </FAQItem>
          <FAQItem
            question={language[langCode].faqPage.faqWhy.title}
            account="2"
          >
            <p>{language[langCode].faqPage.faqWhy.text}</p>
            <ol className="list-decimal pl-5">
              <li>{language[langCode].faqPage.faqWhy.economy}</li>
              <li>{language[langCode].faqPage.faqWhy.economyTime}</li>
              <li>{language[langCode].faqPage.faqWhy.easy}</li>
              <li>{language[langCode].faqPage.faqWhy.change}</li>
            </ol>
          </FAQItem>
          <FAQItem
            question={language[langCode].faqPage.faqTrust.title}
            account="3"
          >
            <p>
              {language[langCode].faqPage.faqTrust.text}
              <br></br>
              {`CoinCharger ${language[langCode].faqPage.inSocial}:`}
              <ul className="list-disc pl-7 text-indigo-200 underline">
                <li>
                  <a href="#">Facebook</a>
                </li>
                <li>
                  <a href="#">Twitter</a>
                </li>
                <li>
                  <a href="#">Instagram</a>
                </li>
              </ul>
              {`CoinCharger ${language[langCode].faqPage.onForums}:`}
              <ul className="list-disc pl-7 text-indigo-200 underline">
                <li>
                  <a href="#">Reddit</a>
                </li>
                <li>
                  <a href="#">Bitcointalk</a>
                </li>
                <li>
                  <a href="#">Medium</a>
                </li>
                <li>
                  <a href="#">Steemit</a>
                </li>
              </ul>
            </p>
          </FAQItem>
          <FAQItem
            question={language[langCode].faqPage.faqHow.title}
            account="3"
          >
            <p>{language[langCode].faqPage.faqHow.text}</p>
            <ol className="list-decimal pl-6">
              <li>{language[langCode].faqPage.faqHow.stepOne}</li>
              <li>{language[langCode].faqPage.faqHow.stepTwo}</li>
              <li>{language[langCode].faqPage.faqHow.stepThree}</li>
            </ol>
          </FAQItem>
        </ul>
      )}
      {aboutExchangeOpen && (
        <ul>
          <FAQItem
            question={language[langCode].faqPage.faqHow.title}
            account="1"
          >
            <p>{language[langCode].faqPage.faqHow.text}</p>
            <ol className="list-decimal pl-6">
              <li>{language[langCode].faqPage.faqHow.stepOne}</li>
              <li>{language[langCode].faqPage.faqHow.stepTwo}</li>
              <li>{language[langCode].faqPage.faqHow.stepThree}</li>
            </ol>
          </FAQItem>
        </ul>
      )}
      {aboutOrderOpen && (
        <ul>
          <FAQItem
            question={language[langCode].faqPage.faqTrack.title}
            account="1"
          >
            <p>{language[langCode].faqPage.faqTrack.text}</p>
          </FAQItem>
        </ul>
      )}
    </section>
  );
};
