import { TransactionsItem } from "./TransactionsItem";
import { language } from "../utils/constants";
import btc from "../images/coins/btc.svg";
import eth from "../images/coins/eth.svg";
import { api } from "../utils/api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
export const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  useEffect(() => {
    api
      .getTransactions()
      .then((data) => {
        console.log(data.transactions);
        setTransactions(data.transactions);
      })
      .catch((err) => console.log(err));
  }, []);

  const langCode = useSelector((state) => state.language);

  return (
    <section className="flex flex-col  bg-no-repeat">
      <h2 className="text-center font-bold text-4xl font-segoe bg-gradient-to-r from-[#FBE3F1] to-[#CAAEFF] bg-clip-text text-transparent mt-12 mb-10 self-center">
        {language[langCode].transactions.title}
      </h2>
      <ul className="flex flex-col items-center">
        {transactions.map((transaction) => (
          <TransactionsItem
            key={transaction.date}
            from={transaction.from}
            to={transaction.to}
            firstCoin={btc}
            secondCoin={eth}
            time={transaction.time}
            date={transaction.date}
            amount={transaction.amount}
          />
        ))}
      </ul>
    </section>
  );
};
