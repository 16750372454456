// img
import warning from "../images/icons/warningRed.svg";
import unselectedRadio from "../images/icons/unselectedRadio.svg";
import selectedRadio from "../images/icons/selectedRadio.svg";
import close from "../images/icons/close.svg";

// lib
import "@splidejs/react-splide/css/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QrReader from "react-qr-scanner";
import classNames from "classnames";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useParams } from "react-router-dom";

import { addToOrders, withdrawFromOrders } from "../utils/helpers";
import { api } from "../utils/api";
import { setOrder } from "../store/actions";
import { InputAddressCoin } from "./InputAddressCoin";

import { language } from "../utils/constants";

export const SendingActionsExpiredOrder = () => {
  const iphone = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 744px)"
  );

  const desctop = useMediaQuery("only screen and (min-width : 1440px)");

  const dispatch = useDispatch();

  const [isReturn, setIsReturn] = useState(true);
  const [coinAddress, setCoinAddress] = useState("");
  const [invalidAddress, setInvalidAddress] = useState("");
  const [openQR, setOpenQR] = useState(false);
  const order = useSelector((state) => state.order);
  const [memoTag, setMemoTag] = useState("");
  const [localOrder, setLocalOrder] = useState(
    withdrawFromOrders(useParams().id) || {}
  );

  const previewStyle = {
    height: "100%",
    width: "80vw",
  };

  const langCode = useSelector((state) => state.language);

  return (
    <div
      className={classNames("bg-account rounded-3xl", {
        "px-12 py-10": !iphone,
        "px-4 py-10": iphone,
      })}
    >
      <div
        className={classNames("flex items-center text-[32px] font-bold mb-6", {
          "text-xl": iphone,
        })}
      >
        <img className="mr-2 w-10 h-10" src={warning} alt="" />
        {language[langCode].sendingPage.expired.sended}
      </div>
      <p
        className={classNames("text-left font-semibold text-2xl mb-6", {
          " text-base": iphone,
        })}
      >
        {language[langCode].sendingPage.expired.notRecieved}
      </p>
      <form
        className="flex flex-col items-start"
        onSubmit={(ev) => {
          ev.preventDefault();
          // SUBMIT
          isReturn
            ? api
                .cancelOrder(
                  memoTag === ""
                    ? {
                        id: order.id,
                        token: order.token,
                        choice: "REFUND",
                        address: coinAddress,
                      }
                    : {
                        id: order.id,
                        token: order.token,
                        choice: "REFUND",
                        address: coinAddress,
                        tag: memoTag,
                      }
                )
                .then((res) => {
                  if (res.msg === "OK") {
                    order &&
                      api
                        .getOrder({
                          id: localOrder.id,
                          token: localOrder.token,
                        })
                        .then((response) => {
                          console.log(response);
                          dispatch(setOrder(response.data));
                          localStorage.setItem(
                            "order",
                            JSON.stringify(response.data)
                          );
                          addToOrders(response.data);
                        });
                  } else if (res.msg === "Invalid address") {
                    setInvalidAddress(
                      language[langCode].sendingPage.expired.notAdress
                    );
                  }
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                })
            : api
                .cancelOrder({
                  id: order.id,
                  token: order.token,
                  choice: "EXCHANGE",
                })
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                });
        }}
      >
        <label
          htmlFor="continue"
          className={classNames(
            "flex items-center text-left font-semibold mb-4 mb-4 cursor-pointer",
            {
              "text-xl": !iphone,
              "text-base": iphone,
            }
          )}
          onClick={() => setIsReturn(false)}
        >
          <input className="hidden" type="radio" name="action" id="continue" />
          <img
            className="mr-2"
            src={isReturn ? unselectedRadio : selectedRadio}
            alt=""
          />
          {language[langCode].sendingPage.expired.notAdress}
        </label>
        <label
          htmlFor="return"
          className={classNames(
            "flex items-center text-left font-semibold mb-4 cursor-pointer",
            {
              "text-xl": !iphone,
              "text-base": iphone,
            }
          )}
          onClick={() => setIsReturn(true)}
        >
          <input className="hidden" type="radio" name="action" id="return" />
          <img
            className="mr-2"
            src={isReturn ? selectedRadio : unselectedRadio}
            alt=""
          />
          {language[langCode].sendingPage.expired.return}
        </label>

        {!isReturn && (
          <button
            className="bg-btns rounded-xl text-xl px-4 py-3 font-semibold"
            type="submit"
          >
            {language[langCode].sendingPage.expired.continueShort}
          </button>
        )}

        {isReturn && (
          <div
            className={classNames("flex w-full justify-between", {
              "flex-col": !desctop,
            })}
          >
            <div
              className={classNames("w-full", {
                "mr-4": desctop,
              })}
            >
              <InputAddressCoin
                stateAddress={coinAddress}
                setStateAddress={setCoinAddress}
                stateMemo={memoTag}
                setStateMemo={setMemoTag}
                invalidAddress={invalidAddress}
                setInvalidAddress={setInvalidAddress}
                openQR={openQR}
                setOpenQR={setOpenQR}
              />
            </div>
            <button
              className="self-start bg-btns rounded-xl text-xl px-4 py-3 font-semibold whitespace-nowrap"
              type="submit"
            >
              {language[langCode].sendingPage.expired.receiveShort}
            </button>
          </div>
        )}
      </form>
      {openQR && (
        <div className="fixed flex justify-center items-center top-0 left-0 w-screen h-screen bg-black z-20">
          <button
            className="absolute top-10 right-10"
            onClick={() => setOpenQR(false)}
          >
            <img src={close} alt="X" />
          </button>
          <QrReader
            delay={500}
            style={previewStyle}
            onError={(err) => {
              console.log(err);
            }}
            onScan={(data) => {
              if (data !== null) {
                setCoinAddress(data.text);
                setOpenQR(false);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};
