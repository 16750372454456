import arrow from "../images/purple-arrow.svg";
import timeImg from "../images/icons/time-icon.svg";
import logoIcon from "../images/logo.png";

import { useMediaQuery } from "@uidotdev/usehooks";
import classNames from "classnames";

import ReactTimeAgo from "react-time-ago";
import { useSelector } from "react-redux";
import { language } from "../utils/constants";

export const TransactionsItem = ({
  from,
  to,
  date,
  firstCoin,
  secondCoin,
  time,
  amount,
}) => {
  const iphone = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 744px)"
  );
  const ipadMini = useMediaQuery(
    "only screen and (min-width : 744px) and (max-width : 1024px)"
  );

  const coins = useSelector((state) => state.coins);
  const langCode = useSelector((state) => state.language);

  function createTextTime(second) {
    if (second < 60) {
      return `${second} sec`;
    } else if (second < 3600) {
      return `${Math.floor(second / 60)} min`;
    } else {
      return `${Math.floor(second / 3600)} hour`;
    }
  }

  function getSrcLogo(coin) {
    const logo = coins.find((c) => c.code === coin)?.logo;
    return logo ? logo : logoIcon;
  }

  return (
    <div
      className={classNames(
        "bg-order flex flex-row items-center rounded-3xl pt-6 w-full justify-between mb-4 max-w-1328 mx-14",
        {
          "flex-col ": ipadMini || iphone,
          "p-6": !ipadMini && !iphone,
        }
      )}
    >
      {!ipadMini && !iphone && (
        <>
          <p className=" text-start text-xl font-semibold w-52">
            <ReactTimeAgo date={date} locale={langCode} />
          </p>
          <div className="flex flex-row items-center flex-grow">
            <div className="flex flex-row items-center justify-end w-[46%]">
              <p className="mr-2">{amount}</p>
              <p className="mr-2">{from}</p>
              <img
                className="mr-2 w-10 h-10"
                src={getSrcLogo(from)}
                alt={language[langCode].transactions.coinAlt}
              />
            </div>
            <div className="w-[8%] flex justify-center">
              <img
                className="mr-2"
                src={arrow}
                alt={language[langCode].transactions.arrowAlt}
              />
            </div>
            <div className="flex flex-row items-center w-[46%]">
              <img
                className="mr-2 w-10 h-10"
                src={getSrcLogo(to)}
                alt={language[langCode].transactions.coinAlt}
              />
              <p className="mr-2">{to}</p>
            </div>
          </div>
          <div className="flex flex-row items-center justify-end w-52">
            <img
              className="mr-2"
              src={timeImg}
              alt={language[langCode].transactions.timeAlt}
            />
            {createTextTime(time)}
          </div>
        </>
      )}
      {(ipadMini || iphone) && (
        <>
          <div className="flex flex-row items-center justify-between w-full border-b pb-2 border-purple-700">
            <p className="text-start text-base font-normal flex-grow ml-6">
              <ReactTimeAgo date={date} locale={langCode} />
            </p>
            <div className="flex flex-row items-center mr-6">
              <img className="mr-2" src={timeImg} />
              {createTextTime(time)}
            </div>
          </div>
          <div className="flex flex-row items-center py-3 w-full">
            <div className="flex flex-row flex-wrap items-center justify-end w-[50%]">
              <p className={classNames("mr-1", { "text-sm": iphone })}>
                {amount}
              </p>
              <p className={classNames("mr-1", { "text-sm": iphone })}>
                {from}
              </p>
              <img
                className={classNames("mr-1 w-10 h-10", { "w-6 h-6": iphone })}
                src={getSrcLogo(from)}
                alt={language[langCode].transactions.coinAlt}
              />
            </div>
            <div className="w-[8%] flex justify-center">
              <img
                className="mr-1"
                src={arrow}
                alt={language[langCode].transactions.arrowAlt}
              />
            </div>
            <div className="flex flex-row items-center w-[42%]">
              <img
                className={classNames("mr-1 w-10 h-10", { "w-6 h-6": iphone })}
                src={getSrcLogo(to)}
                alt={language[langCode].transactions.coinAlt}
              />
              <p className={classNames("mr-1", { "text-sm": iphone })}>{to}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
