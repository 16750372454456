// lib
import { useMediaQuery } from "@uidotdev/usehooks";
import classNames from "classnames";
import { useSelector } from "react-redux";

// img
import qr from "../images/icons/qr.svg";
import squares from "../images/icons/squares.svg";
import close from "../images/icons/close.svg";
import warning from "../images/icons/warning.svg";

import { language } from "../utils/constants";

export const InputAddressCoin = ({
  stateAddress,
  setStateAddress,
  stateMemo = "",
  setStateMemo = () => {},
  invalidAddress,
  setInvalidAddress,
  setOpenQR,
}) => {
  const iphone = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 744px)"
  );
  const ipadMini = useMediaQuery(
    "only screen and (min-width : 744px) and (max-width : 1024px)"
  );
  const macbook = useMediaQuery(
    "only screen and (min-width : 1024px) and (max-width : 1440px)"
  );
  const desctop = useMediaQuery("only screen and (min-width : 1440px)");

  const coins = useSelector((state) => state.coins);
  const sendedCoin = useSelector((state) => state.order.from);
  const sendedCoinTag = coins.find(
    (coin) => coin.code === sendedCoin.code
  )?.tag;

  const langCode = useSelector((state) => state.language);

  return (
    <div className="flex w-full flex-wrap justify-between">
      <div
        className={classNames(
          "bg-[#08035B] h-[52px] flex flex-row py-3 px-6 rounded-xl justify-between flex-grow relative",
          {
            "w-[90%]": macbook,
            "w-full": ipadMini || iphone,
          }
        )}
      >
        <input
          className="bg-[#08035B] text-white focus:outline-none w-3/4"
          type="text"
          value={stateAddress}
          placeholder={`${language[langCode].sendingPage.txDone.your} ${sendedCoin.name} ${language[langCode].sendingPage.txDone.address}`}
          onChange={(ev) => {
            setStateAddress(ev.target.value.replace(/[\\а-яА-Я]/g, ""));
            setInvalidAddress(false);
          }}
        />
        <div className="flex flex-row">
          {stateAddress === "" && (
            <>
              <img
                onClick={() => setOpenQR(true)}
                className="mr-3 cursor-pointer"
                src={qr}
                alt={language[langCode].sendingPage.qr.qrAlt}
              />
              <img
                className="cursor-pointer"
                onClick={() => {
                  navigator.clipboard
                    .readText()
                    .then((clipText) => setStateAddress(clipText))
                    .catch((err) => {
                      alert(language[langCode].sendingPage.error.buffer);
                    });
                  setInvalidAddress(false);
                }}
                src={squares}
                alt={language[langCode].sendingPage.error.pasteAlt}
              />
            </>
          )}
          {stateAddress !== "" && (
            <img
              onClick={() => setStateAddress("")}
              className="cursor-pointer w-6 h-6"
              src={close}
              alt={language[langCode].sendingPage.error.cancelAlt}
            />
          )}
        </div>
        {invalidAddress && (
          <div className="absolute top-full left-0 flex justify-between items-center self-start px-3 py-1 bg-[#FF5454] rounded-lg mt-1">
            <img className="fill-white" src={warning} alt="" />
            <p className="text-[#08035B] ml-2">{invalidAddress}</p>
          </div>
        )}
      </div>
      {sendedCoinTag && (
        <div
          className={classNames(
            "bg-[#08035B] h-[52px] flex flex-row py-3 px-6 rounded-xl justify-between",
            {
              "mb-4 w-[30%]": macbook,
              "mb-4 w-[50%]": ipadMini,
              "mb-4 w-full": iphone,
              "ml-4": desctop,
            }
          )}
        >
          <input
            className="bg-[#08035B] text-white focus:outline-none w-[160px]"
            type="text"
            value={stateMemo}
            placeholder={language[langCode].sendingPage.error.destination}
            onChange={(ev) => setStateMemo(ev.target.value)}
            maxLength={20}
          />
          <div className="flex flex-row">
            {stateMemo === "" && (
              <img
                className="cursor-pointer"
                onClick={() => {
                  navigator.clipboard
                    .readText()
                    .then((clipText) => {
                      setStateMemo(clipText);
                    })
                    .catch((err) => {
                      alert(language[langCode].sendingPage.error.buffer);
                    });
                }}
                src={squares}
                alt={language[langCode].sendingPage.error.pasteAlt}
              />
            )}
            {stateMemo !== "" && (
              <img
                onClick={() => setStateMemo("")}
                className="cursor-pointer w-6 h-6 "
                src={close}
                alt={language[langCode].sendingPage.error.cancelAlt}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
