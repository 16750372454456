import "./App.scss";
import { Routes, Route, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Layout } from "./components/Layout";
import { Main } from "./pages/Main";
import { AboutUs } from "./pages/AboutUs";
import { Blog } from "./pages/Blog";
import { Faq } from "./pages/Faq";
import { ApiPage } from "./pages/ApiPage";
import { Support } from "./pages/Support";
import { SendingPage } from "./pages/SendingPage";
import { Account } from "./pages/Account";
import { useDispatch, useSelector } from "react-redux";
import { closeAllDropdowns, setCoins } from "./store/actions";
import { useState, useEffect } from "react";
import { api } from "./utils/api";
import { NewsPage } from "./components/NewsPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { language } from "./utils/constants";

export const App = () => {
  const dispatch = useDispatch();

  const pathname = useLocation().pathname;

  const state = useSelector((state) => state);

  const [localCoinSend, localCoinRecv] = JSON.parse(
    localStorage.getItem("orderCoins") || "[null, null]"
  );

  const [coinSend, setCoinSent] = useState(
    localCoinSend?.coin || state.creatingOrder.from.code
  );
  const [coinRecv, setCoinRecv] = useState(
    localCoinRecv?.coin || state.creatingOrder.to.code
  );

  const langCode = useSelector((state) => state.language);

  useEffect(() => {
    api
      .getCurrencies()
      .then((response) => {
        if (response.code === 503) {
          throw new Error("Ошибка: 503");
        }
        dispatch(setCoins(response.data));
      })
      .catch((err) => {
        switch (err) {
          case "Ошибка: 404":
            alert(language[langCode].errors.cciesError);
            break;
          case "Ошибка: 503":
            alert(language[langCode].errors.techError);
            break;
          default:
            alert(language[langCode].errors.wrongError);
        }
        console.log(err);
      });
  }, []);

  return (
    <div
      onClick={() => dispatch(closeAllDropdowns())}
      className={classNames(
        "App bg-[center_-500px] bg-main-bg  overflow-x-hidden no-scrollbar",
        {
          "bg-main-bg-img": !pathname.includes("news"),
        }
      )}
    >
      <Routes>
        <Route
          path="/"
          element={
            <Layout setCoinSent={setCoinSent} setCoinRecv={setCoinRecv} />
          }
        >
          <Route
            index
            element={
              <Main
                coinSend={coinSend}
                coinRecv={coinRecv}
                setCoinSent={setCoinSent}
                setCoinRecv={setCoinRecv}
              />
            }
          />
          <Route path="about" element={<AboutUs />} />
          <Route path="blog" element={<Blog />} />
          <Route path="faq" element={<Faq />} />
          <Route path="api" element={<ApiPage />} />
          <Route path="support" element={<Support />} />
          <Route path="sending/:id" element={<SendingPage />} />
          <Route path="account/*" element={<Account />} />
          <Route path="news/:id" element={<NewsPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </div>
  );
};
