import lightningIcon from "../images/sending-icons/lightning.svg";
import bitcoinIcon from "../images/sending-icons/bitcoin.svg";
import timeIcon from "../images/sending-icons/time.svg";
import segwitIcon from "../images/sending-icons/segwit.svg";
import calculatorIcon from "../images/sending-icons/calc.svg";

export const MAIN_URL = "https://ccex.money/api";
// export const MAIN_URL = "http://localhost:3232/api";

export const toKnow = [
  {
    code: "BTC",
    coin: "Биткоин (BTC)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение блокчейна Bitcoin для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Скорость подтверждения транзакции Bitcoin зависит от уровня загруженности сети блокчейн, подробнее в нашей [статье](https://fixedfloat.com/blog/guides/why-bitcoin-is-not-confirmed)",
      },
      {
        icon: segwitIcon,
        text: "Мы используем segwit адреса Bitcoin для более быстрых и дешёвых транзакций, если ваш кошелёк не поддерживает этот тип адресов, обратитесь в техподдержку для смены адреса",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение блокчейна Bitcoin для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Скорость подтверждения транзакции Bitcoin зависит от уровня загруженности сети блокчейн, подробнее в нашей [статье](https://fixedfloat.com/blog/guides/why-bitcoin-is-not-confirmed)",
      },
      {
        icon: segwitIcon,
        text: "Мы используем segwit адреса Bitcoin для более быстрых и дешёвых транзакций, если ваш кошелёк не поддерживает этот тип адресов, обратитесь в техподдержку для смены адреса",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "ETH",
    coin: "Ethereum (ETH)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения блокчейна Ethereum для осуществления обмена",
      },
      {
        icon: segwitIcon,
        text: "Мы просим вас не отправлять Ethereum через смарт-контракт. Такие транзакции не обрабатываются нашей системой автоматически",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения блокчейна Ethereum для осуществления обмена",
      },
      {
        icon: segwitIcon,
        text: "Мы просим вас не отправлять Ethereum через смарт-контракт. Такие транзакции не обрабатываются нашей системой автоматически",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "USDT",
    coin: "Tehter (USDT)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения USDT для осуществления обмена",
      },
      {
        icon: segwitIcon,
        text: "Не отправляйте USDT через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта](https://etherscan.io/token/0xdac17f958d2ee523a2206206994597c13d831ec7) USDT",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Ethereum. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения USDT для осуществления обмена",
      },
      {
        icon: segwitIcon,
        text: "Не отправляйте USDT через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта](https://etherscan.io/token/0xdac17f958d2ee523a2206206994597c13d831ec7) USDT",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Ethereum. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "LTC",
    coin: "Litecoin (LTC)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 2 подтверждения блокчейна Litecoin для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 2 подтверждения блокчейна Litecoin для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "XMR",
    coin: "Monero (XMR)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 2 подтверждения блокчейна Monero для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "При отправке Monero Payment ID вводить не нужно, мы используем интегрированные кошельки",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 2 подтверждения блокчейна Monero для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "При отправке Monero Payment ID вводить не нужно, мы используем интегрированные кошельки",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "ZRX",
    coin: "ERC20 ZRX(ETH Network)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения ZRX для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте ZRX через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта ZRX](https://etherscan.io/token/0xe41d2489571d322189246dafa5ebde1f4699f498)",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения ZRX для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте ZRX через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта ZRX](https://etherscan.io/token/0xe41d2489571d322189246dafa5ebde1f4699f498)",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "AAVEETH",
    coin: "AAVE ERC (ETH Network)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения AAVE для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте AAVE через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта](https://etherscan.io/address/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9) AAVE",
      },
      {
        icon: segwitIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Ethereum. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения AAVE для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте AAVE через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта](https://etherscan.io/address/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9) AAVE",
      },
      {
        icon: segwitIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Ethereum. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "BNB",
    coin: "BNB Beacon Chain (BEP2)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 1 подтверждение блокчейна BNB для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "При отправке BNB Memo вводить не нужно",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 1 подтверждение блокчейна BNB для осуществления обмена",
      },
      { icon: bitcoinIcon, text: "При отправке BNB Memo вводить не нужно" },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "BSC",
    coin: "BNB Smart Chain (BEP20)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения Binance Smart Chain для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения Binance Smart Chain для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "BAT",
    coin: "Basic Attention ERC20 BAT(ETH network)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения BAT для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте BAT через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта BAT](https://etherscan.io/token/0x0d8775f648430679a709e98d2b0cb6250d2887ef)",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения BAT для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте BAT через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта BAT](https://etherscan.io/token/0x0d8775f648430679a709e98d2b0cb6250d2887ef)",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  // {
  //   code: "BAT",
  //   coin: "Basic Attention ERC20 BAT(ETH network)",
  //   float: [
  //     {
  //       icon: lightningIcon,
  //       text: "Нужно 4 подтверждения BAT для осуществления обмена",
  //     },
  //     {
  //       icon: bitcoinIcon,
  //       text: "Не отправляйте BAT через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта BAT](https://etherscan.io/token/0x0d8775f648430679a709e98d2b0cb6250d2887ef)",
  //     },
  //     {
  //       icon: calculatorIcon,
  //       text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
  //     },
  //     {
  //       icon: timeIcon,
  //       text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
  //     },
  //   ],
  // },
  {
    code: "BTT",
    coin: "BitTorrent (BTT) (TRX Network)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение BTT для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение BTT для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "BTCBNB",
    coin: "Bitcoin (BEP2) BNB Network",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 1 подтверждение блокчейна Binance chain для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "При отправке BTC BEP2 Memo вводить не нужно",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 1 подтверждение блокчейна Binance chain для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "При отправке BTC BEP2 Memo вводить не нужно",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "BTCBSC",
    coin: "Bitcoin (BEP20) BSC Network",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения BTC BEP20 для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения BTC BEP20 для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "BTCLN",
    coin: "Bitcoin(BTC) LN Network (Lightning)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение Lightning Network для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Если при отправке BTCLN возникает ошибка, значит вам требуется открыть канал с FixedFloat. Информация о том, как это сделать, есть в нашей [статье](https://fixedfloat.com/blog/lightning/start-using-lightning-network)",
      },
      {
        icon: segwitIcon,
        text: 'Если при отправке BTCLN вы видите сообщение "Payment in transit", значит существует проблема между промежуточными нодами. Нужно дождаться окончательной оплаты инвойса или возврат средств',
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение Lightning Network для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Если при отправке BTCLN возникает ошибка, значит вам требуется открыть канал с FixedFloat. Информация о том, как это сделать, есть в нашей [статье](https://fixedfloat.com/blog/lightning/start-using-lightning-network)",
      },
      {
        icon: segwitIcon,
        text: 'Если при отправке BTCLN вы видите сообщение "Payment in transit", значит существует проблема между промежуточными нодами. Нужно дождаться окончательной оплаты инвойса или возврат средств',
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "BCH",
    coin: "Bitcoin Cash(BCH)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно всего 2 подтверждения блокчейна Bitcoin Cash для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: 'Если у Вас не получается отправить средства на наш адрес, попробуйте добавить в начало адреса "bitcoincash:" или воспользуйтесь [сервисом конвертирования адреса](https://cashaddr.bitcoincash.org/)',
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно всего 2 подтверждения блокчейна Bitcoin Cash для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: 'Если у Вас не получается отправить средства на наш адрес, попробуйте добавить в начало адреса "bitcoincash:" или воспользуйтесь [сервисом конвертирования адреса](https://cashaddr.bitcoincash.org/)',
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "ADA",
    coin: "Cardano (ADA)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно всего 15 подтверждений блокчейна ADA для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно всего 15 подтверждений блокчейна ADA для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "ADABSC",
    coin: "Cardano (BEP20)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения ADA BEP20 для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Binance Smart Chain. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения ADA BEP20 для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Binance Smart Chain. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "AVAX",
    coin: "Avalanche (C-Chain)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 3 подтверждения сети Avalanche для осуществления обмена.",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию AVAX только в сети Avalanche C-CHAIN. Если монеты будут отправлены в другой сети, заказ не будет выполнен.",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 3 подтверждения сети Avalanche для осуществления обмена.",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию AVAX только в сети Avalanche C-CHAIN. Если монеты будут отправлены в другой сети, заказ не будет выполнен.",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "ADABNB",
    coin: "Cardano ADA BEP2 (BSC Network)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 1 подтверждение блокчейна Binance chain для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "При отправке ADA BEP2 Memo вводить не нужно",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 1 подтверждение блокчейна Binance chain для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "При отправке ADA BEP2 Memo вводить не нужно",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "LINK",
    coin: "Chainlink(LINK) ERC20 (ETH Network)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения LINK для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте LINK через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта](https://etherscan.io/address/0x514910771af9ca656af840dff83e8264ecf986ca) LINK",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения LINK для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте LINK через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта](https://etherscan.io/address/0x514910771af9ca656af840dff83e8264ecf986ca) LINK",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "ATOM",
    coin: "Cosmos (ATOM)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 3 подтверждения Cosmos для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "При отправке ATOM Memo вводить не нужно",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 3 подтверждения Cosmos для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "При отправке ATOM Memo вводить не нужно",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "DAIMATIC",
    coin: "DAI (Polygon)",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "DAIBSC",
    coin: "DAI BEP20 BSC Network",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения DAI BEP20 для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения DAI BEP20 для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "DAIETH",
    coin: "DAI ERC20 ETH Network",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения DAI для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте DAI через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта](https://etherscan.io/token/0x6b175474e89094c44da98b954eedeac495271d0f) DAI",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения DAI для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте DAI через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта](https://etherscan.io/token/0x6b175474e89094c44da98b954eedeac495271d0f) DAI",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "DASH",
    coin: "DASH",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 1 подтверждения блокчейна Dash для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 1 подтверждения блокчейна Dash для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "MANAETH",
    coin: "Decentraland MANA ERC20 ETH Network",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "DOGE",
    coin: "Dogecoin DOGE",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно всего 4 подтверждения блокчейна DOGE для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно всего 4 подтверждения блокчейна DOGE для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "EOS",
    coin: "EOS",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "ETHARBITRUM",
    coin: "ETH Arbitrum",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "ETHBNB",
    coin: "Ethereum ETH BEP2 (BNB Network)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 1 подтверждение блокчейна Binance chain для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "При отправке ETH BEP2 Memo вводить не нужно",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 1 подтверждение блокчейна Binance chain для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "При отправке ETH BEP2 Memo вводить не нужно",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "ETHBSC",
    coin: "Ethereum ETH BEP20 (BSC Network)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения ETH BEP20 для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения ETH BEP20 для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "ETC",
    coin: "Ethereum Classic ETC",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно всего 30 подтверждений блокчейна ETC для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно всего 30 подтверждений блокчейна ETC для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "FTM",
    coin: "Fantom FTM",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "MKR",
    coin: "Maker MKR ERC20 (ETH Network)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения MKR для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте MKR через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта](https://etherscan.io/token/0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2) MKR",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения MKR для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте MKR через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта](https://etherscan.io/token/0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2) MKR",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "PAXGETH",
    coin: "PAX Gold PAXG (ETH Network)",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "CAKE",
    coin: "PancakeSwap (CAKE) BEP20 BSC Network",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения CAKE для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Binance Smart Chain. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения CAKE для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Binance Smart Chain. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "USDP",
    coin: "PaxDollar USDP (ETH Network)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения PAX для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте PAX через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта](https://etherscan.io/token/0x8e870d67f660d95d5be530380d0ec0bd388289e1) PAX",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения PAX для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте PAX через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта](https://etherscan.io/token/0x8e870d67f660d95d5be530380d0ec0bd388289e1) PAX",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "DOT",
    coin: "Polkadot DOT",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "MATIC",
    coin: "Polygon MATIC",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 30 подтверждения MATIC для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Polygon. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 30 подтверждения MATIC для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Polygon. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "MATICETH",
    coin: "Polygon MATIC ERC20 (ETH Network)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения MATIC для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Ethereum. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения MATIC для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Ethereum. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "XRP",
    coin: "Ripple XRP",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение блокчейна XRP для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Если используете R-адрес, обязательно укажите Destination tag (DT)",
      },
      {
        icon: segwitIcon,
        text: "Если кошелёк поддерживает [X-адрес](https://fixedfloat.com/blog/guides/xrp-x-address), Destination tag указывать не нужно",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение блокчейна XRP для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Если используете R-адрес, обязательно укажите Destination tag (DT)",
      },
      {
        icon: segwitIcon,
        text: "Если кошелёк поддерживает [X-адрес](https://fixedfloat.com/blog/guides/xrp-x-address), Destination tag указывать не нужно",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "SHIB",
    coin: "Shiba Inu SHIB ERC20 (ETH Network)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения SHIB для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Ethereum. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения SHIB для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Ethereum. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "USDTARBITRUM",
    coin: "Tether USDT Arbitrum Network",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "USDTBNB",
    coin: "Tether USDT BEP2 BNB Network",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 1 подтверждение блокчейна Binance chain для осуществления обмена",
      },
      { icon: bitcoinIcon, text: "При отправке USDT Memo вводить не нужно" },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 1 подтверждение блокчейна Binance chain для осуществления обмена",
      },
      { icon: bitcoinIcon, text: "При отправке USDT Memo вводить не нужно" },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "USDTMATIC",
    coin: "Tether USDT Polygon Network",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "USDTTRC",
    coin: "Tether USDT TRX Network",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение блокчейна TRX для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение блокчейна TRX для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "XTZ",
    coin: "Tezos XTZ",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения Tezos для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения Tezos для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "TON",
    coin: "Toncoin TON",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение блокчейна TON для осуществления обмена",
      },
      { icon: bitcoinIcon, text: "При отправке TON обязательно укажите Memo" },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение блокчейна TON для осуществления обмена",
      },
      { icon: bitcoinIcon, text: "При отправке TON обязательно укажите Memo" },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "TRX",
    coin: "Tron TRX",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение блокчейна TRX для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Мы просим вас не отправлять Tron через смарт-контракт. Такие транзакции не обрабатываются нашей системой автоматически",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение блокчейна TRX для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Мы просим вас не отправлять Tron через смарт-контракт. Такие транзакции не обрабатываются нашей системой автоматически",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "TUSD",
    coin: "TrueUSD TUSD",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения TUSD для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте TUSD через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта](https://etherscan.io/token/0x0000000000085d4780B73119b644AE5ecd22b376) TUSD",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения TUSD для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Не отправляйте TUSD через сторонний контракт! Такие транзакции не обрабатываются автоматически. Мы принимаем транзакции с [оригинального контракта](https://etherscan.io/token/0x0000000000085d4780B73119b644AE5ecd22b376) TUSD",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "TWT",
    coin: "Trust Wallet Token TWT BEP2 BNB Network",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 1 подтверждение блокчейна Binance chain для осуществления обмена",
      },
      { icon: bitcoinIcon, text: "При отправке TWT Memo вводить не нужно" },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 1 подтверждение блокчейна Binance chain для осуществления обмена",
      },
      { icon: bitcoinIcon, text: "При отправке TWT Memo вводить не нужно" },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "TWTBSC",
    coin: "Trust Wallet Token TWT BEP20 BNS Networ",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "USDCBSC",
    coin: "USD Coin USDC BEP20 BNS Network",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения USDC BEP20 для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Binance Smart Chain. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения USDC BEP20 для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Binance Smart Chain. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "USDCETH",
    coin: "USD Coin USDC ERC20 ETH Network",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "USDCTRC",
    coin: "USD Coin USDC TRX Network",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение блокчейна TRX для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно всего 1 подтверждение блокчейна TRX для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "VET",
    coin: "VeChain VET",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 12 подтверждения сети VeChain для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 12 подтверждения сети VeChain для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "WBNBBSC",
    coin: "Wrapped BNB WBNB BEP20 (BSC Network)",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "WETHETH",
    coin: "Wrapped ETH WETH ERC20 ETH Network",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "ZEC",
    coin: "ZCash (ZEC)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения блокчейна Zcash для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения блокчейна Zcash для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "KCS",
    coin: "KuCoin Token",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "SHIBBSC",
    coin: "SHIBA INU (BEP20)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения SHIB BEP20 для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Binance Smart Chain. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения SHIB BEP20 для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "Пожалуйста, отправляйте транзакцию только в сети Binance Smart Chain. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "SOL",
    coin: "Solana",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 3 подтверждения Solana для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 3 подтверждения Solana для осуществления обмена",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "WSOL",
    coin: "Wrapped SOL (Solana)",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "USDCSOL",
    coin: "USD Coin (Solana)",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "USDCeMATIC",
    coin: "USD Coin PoS (Polygon)",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "USDTBSC",
    coin: "Tether (BEP20)",
    float: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения USDT BEP20 для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "­Пожалуйста, отправляйте транзакцию только в сети Binance Smart Chain. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: lightningIcon,
        text: "Нужно 4 подтверждения USDT BEP20 для осуществления обмена",
      },
      {
        icon: bitcoinIcon,
        text: "­Пожалуйста, отправляйте транзакцию только в сети Binance Smart Chain. Если монеты будут отправлены в другой сети, заказ не будет выполнен автоматически",
      },
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "USDTSOL",
    coin: "Tether (Solana)",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
  {
    code: "XLM",
    coin: "Stellar Lumens (XLM)",
    float: [
      {
        icon: calculatorIcon,
        text: "Если сумма отправленной вами транзакции будет отличаться от первоначальной суммы указанной в заказе с плавающим курсом, заказ будет автоматически пересчитан.",
      },
      {
        icon: timeIcon,
        text: "Если ваша транзакция поступит после истечения заказа, но в течение 24 часов, то данная транзакция автоматически отобразится в заказе. Вы сможете самостоятельно продолжить заказ или осуществить возврат средств.",
      },
    ],
    fixed: [
      {
        icon: calculatorIcon,
        text: "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
      },
    ],
  },
];

export const blockchains = [
  {
    name: "Aave (ERC20)",
    txIds: [
      "https://blockchair.com/ethereum/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
  {
    name: "Cardano (ADA)",
    txIds: [
      "https://blockchair.com/cardano/transaction/{txID}",
      "https://cardanoscan.io/transaction/{txID}",
      "https://explorer.cardano.org/en/transaction?id={txID}",
    ],
  },
  {
    name: "Cardano (BEP2)",
    txIds: ["https://explorer.bnbchain.org/tx/{txID}"],
  },
  {
    name: "Cardano (BEP20)",
    txIds: ["https://bscscan.com/tx/{txID}"],
  },
  {
    name: "Cosmos (ATOM)",
    txIds: [
      "https://www.mintscan.io/cosmos/txs/{txID}",
      "https://atomscan.com/transactions/{txID}",
    ],
  },
  {
    name: "Avalanche (C-Chain) (AVAX)",
    txIds: [
      "https://snowtrace.io/tx/{txID}",
      "https://avascan.info/blockchain/c/tx/{txID}",
    ],
  },
  {
    name: "Basic Attention (ERC20) (BAT)",
    txIds: [
      "https://blockchair.com/ethereum/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
  {
    name: "Bitcoin Cash (BCH)",
    txIds: [
      "https://blockchair.com/bitcoin-cash/transaction/{txID}",
      "https://www.blockchain.com/explorer/transactions/bch/{txID}",
    ],
  },
  {
    name: "BNB Beacon Chain (BEP2)",
    txIds: ["https://explorer.bnbchain.org/tx/{txID}"],
  },
  {
    name: "BNB Smart Chain (BEP20)",
    txIds: ["https://bscscan.com/tx/{txID}"],
  },
  {
    name: "Bitcoin (BTC)",
    txIds: ["https://mempool.space/ru/tx/{txID}"],
  },
  {
    name: "Bitcoin (BEP2)",
    txIds: ["https://explorer.bnbchain.org/tx/{txID}"],
  },
  {
    name: "Bitcoin (BEP20)",
    txIds: ["https://bscscan.com/tx/{txID}"],
  },
  {
    name: "Bitcoin (Lightning)",
    txIds: ["private network, no link"],
  },
  {
    name: "BitTorrent (BTT)",
    txIds: ["https://tronscan.org/#/transaction/{txID}"],
  },
  {
    name: "PancakeSwap (BEP20)",
    txIds: ["https://bscscan.com/tx/{txID}"],
  },
  {
    name: "DAI (BEP20)",
    txIds: ["https://bscscan.com/tx/{txID}"],
  },
  {
    name: "DAI (ERC20)",
    txIds: [
      "https://blockchair.com/ethereum/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
  {
    name: "DAI (Polygon)",
    txIds: [
      "https://polygonscan.com/tx/{txID}",
      "https://www.oklink.com//tx/{txID}",
    ],
  },
  {
    name: "Dash (DASH)",
    txIds: [
      "https://blockchair.com/dash/transaction/{txID}",
      "https://chainz.cryptoid.info/dash/tx.dws?{txID}.htm",
    ],
  },
  {
    name: "Dogecoin (DOGE)",
    txIds: [
      "https://blockchair.com/dogecoin/transaction/{txID}",
      "https://dogechain.info/tx/{txID}",
      "https://live.blockcypher.com/doge/tx/{txID}",
    ],
  },
  {
    name: "Polkadot (DOT)",
    txIds: [
      "https://polkadot.subscan.io/extrinsic/{txID}",
      "https://polkastats.io/extrinsic/{txID}",
    ],
  },
  {
    name: "EOS (EOS)",
    txIds: [
      "https://eosauthority.com/transaction/{txID}?network=eos",
      "https://bloks.io/transaction/{txID}/",
    ],
  },
  {
    name: "Ethereum Classic (ETC)",
    txIds: [
      "https://etcblockexplorer.com/tx/{txID}",
      "https://etc.blockscout.com/tx/{txID}",
      "https://receipt.emeraldpay.io/tx/{txID}",
    ],
  },
  {
    name: "Ethereum (ETH)",
    txIds: [
      "https://blockchair.com/ethereum/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
  {
    name: "Ethereum (Arbitrum)",
    txIds: ["https://arbiscan.io/tx/{txID}"],
  },
  {
    name: "Ethereum (BEP2)",
    txIds: ["https://explorer.bnbchain.org/tx/{txID}"],
  },
  {
    name: "Ethereum (BEP20)",
    txIds: ["https://bscscan.com/tx/{txID}"],
  },
  {
    name: "Wrapped ETH (ERC20)",
    txIds: [
      "https://blockchair.com/ethereum/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
  {
    name: "Fantom (FTM)",
    txIds: ["https://ftmscan.com/tx/{txID}"],
  },
  {
    name: "KuCoin Token (KCS)",
    txIds: ["https://explorer.kcc.io/en/tx/{txID}"],
  },
  {
    name: "Chainlink (ERC20) (LINK)",
    txIds: [
      "https://blockchair.com/ethereum/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
  {
    name: "Litecoin (LTC)",
    txIds: [
      "https://blockchair.com/litecoin/transaction/{txID}",
      "https://chainz.cryptoid.info/ltc/tx.dws?{txID}.htm",
      "https://chain.so/tx/LTC/{txID}/",
    ],
  },
  {
    name: "Decentraland (ERC20) (MANA)",
    txIds: [
      "https://blockchair.com/ethereum/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
  {
    name: "Polygon (MATIC)",
    txIds: [
      "https://polygonscan.com/tx/{txID}",
      "https://www.oklink.com//tx/{txID}",
    ],
  },
  {
    name: "Polygon (ERC20)",
    txIds: [
      "https://blockchair.com/ethereum/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
  {
    name: "Maker (ERC20) (MKR)",
    txIds: [
      "https://blockchair.com/ethereum/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
  {
    name: "SHIBA INU (ERC20)",
    txIds: [
      "https://blockchair.com/ethereum/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
  {
    name: "SHIBA INU (BEP20)",
    txIds: ["https://bscscan.com/tx/{txID}"],
  },
  {
    name: "Solana (SOL)",
    txIds: [
      "https://solscan.io/tx/{txID}",
      "https://solanabeach.io/transaction/{txID}#scrSpySummary",
      "https://solana.fm/tx/{txID}?cluster=mainnet-alpha",
    ],
  },
  {
    name: "Wrapped SOL (SOL) (WSOL)",
    txIds: [
      "https://solscan.io/tx/{txID}",
      "https://solanabeach.io/transaction/{txID}#scrSpySummary",
      "https://solana.fm/tx/{txID}?cluster=mainnet-alpha",
    ],
  },
  {
    name: "Toncoin (TON)",
    txIds: ["https://tonscan.org/tx/{txID}"],
  },
  {
    name: "Tron (TRX)",
    txIds: ["https://tronscan.org/#/transaction/{txID}"],
  },
  {
    name: "TrueUSD (ERC20) (TUSD)",
    txIds: [
      "https://blockchair.com/ethereum/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
  {
    name: "Trust Wallet Token (BEP2)",
    txIds: ["https://explorer.bnbchain.org/tx/{txID}"],
  },
  {
    name: "Trust Wallet Token (BEP20)",
    txIds: ["https://bscscan.com/tx/{txID}"],
  },
  {
    name: "USD Coin (BEP20) (USDC)",
    txIds: ["https://bscscan.com/tx/{txID}"],
  },
  {
    name: "USD Coin (ERC20)",
    txIds: [
      "https://blockchair.com/usd-coin/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
  {
    name: "USD Coin (Polygon)",
    txIds: [
      "https://polygonscan.com/tx/{txID}",
      "https://www.oklink.com//tx/{txID}",
    ],
  },
  {
    name: "USD Coin (Solana)",
    txIds: [
      "https://solscan.io/tx/{txID}",
      "https://solanabeach.io/transaction/{txID}#scrSpySummary",
      "https://solana.fm/tx/{txID}?cluster=mainnet-alpha",
    ],
  },
  {
    name: "USD Coin (TRC20)",
    txIds: ["https://tronscan.org/#/transaction/{txID}"],
  },
  {
    name: "USD Coin PoS (Polygon)",
    txIds: [
      "https://polygonscan.com/tx/{txID}",
      "https://www.oklink.com//tx/{txID}",
    ],
  },
  {
    name: "Pax Dollar (ERC20) (USDP)",
    txIds: [
      "https://blockchair.com/ethereum/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
  {
    name: "Pax Gold (ERC20) (USDP)",
    txIds: [
      "https://blockchair.com/ethereum/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
  {
    name: "Tether (ERC20) (USDT)",
    txIds: [
      "https://blockchair.com/tether/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
  {
    name: "Tether (Arbitrum)",
    txIds: ["https://arbiscan.io/tx/{txID}"],
  },
  {
    name: "Tether (BEP2)",
    txIds: ["https://explorer.bnbchain.org/tx/{txID}"],
  },
  {
    name: "Tether (BEP20)",
    txIds: ["https://bscscan.com/tx/{txID}"],
  },
  {
    name: "Tether (Polygon)",
    txIds: [
      "https://polygonscan.com/tx/{txID}",
      "https://www.oklink.com//tx/{txID}",
    ],
  },
  {
    name: "Tether (Solana)",
    txIds: [
      "https://solscan.io/tx/{txID}",
      "https://solanabeach.io/transaction/{txID}#scrSpySummary",
      "https://solana.fm/tx/{txID}?cluster=mainnet-alpha",
    ],
  },
  {
    name: "Tether (TRC20)",
    txIds: ["https://tronscan.org/#/transaction/{txID}"],
  },
  {
    name: "VeChain (VET)",
    txIds: [
      "https://explore.vechain.org/transactions/{txID}#info",
      "https://vechainstats.com/transaction/{txID}/",
    ],
  },
  {
    name: "Wrapped BNB (BEP20)",
    txIds: [
      "https://blockchair.com/stellar/transaction/{txID}",
      "https://bscscan.com/tx/{txID}",
    ],
  },
  {
    name: "Stellar Lumens (XLM)",
    txIds: ["https://stellarchain.io/transactions/{txID}"],
  },
  {
    name: "Monero (XMR)",
    txIds: [
      "https://blockchair.com/monero/transaction/{txID}",
      "https://monerohash.com/explorer/tx/{txID}",
      "https://monero.com/tx/{txID}/team-fixedfloat/",
    ],
  },
  {
    name: "Ripple (XRP)",
    txIds: [
      "https://blockchair.com/ripple/transaction/{txID}",
      "https://xrpscan.com/tx/{txID}",
    ],
  },
  {
    name: "Tezos (XTZ)",
    txIds: ["https://tzstats.com/{txID}", "https://tzkt.io/{txID}"],
  },
  {
    name: "Zcash (ZEC)",
    txIds: [
      "https://blockchair.com/zcash/transaction/{txID}",
      "https://zcashblockexplorer.com/transactions/{txID}",
      "https://explorer.zcha.in/transactions/{txID}",
    ],
  },
  {
    name: "0x (ERC20)",
    txIds: [
      "https://blockchair.com/ethereum/transaction/{txID}",
      "https://beaconcha.in/tx/{txID}",
      "https://etherscan.io/tx/{txID}",
    ],
  },
];

export const coinsRealNames = {
  Litecoin: "Litecoin (LTC)",
  Cardano: "Cardano (ADA)",
  "Aave (ERC20)": "Aave (ERC20)",
  "Cardano (BEP2)": "Cardano (BEP2)",
  "Cardano (BEP20)": "Cardano (BEP20)",
  Cosmos: "Cosmos (ATOM)",
  "Avalanche (C-Chain)": "Avalanche (C-Chain) (AVAX)",
  "Basic Attention (ERC20)": "Basic Attention (ERC20) (BAT)",
  "Bitcoin Cash": "Bitcoin Cash (BCH)",
  "BNB Beacon Chain (BEP2)": "BNB Beacon Chain (BEP2)",
  "BNB Smart Chain (BEP20)": "BNB Smart Chain (BEP20)",
  "Wrapped BNB (BEP20)": "Wrapped BNB (BEP20)",
  Bitcoin: "Bitcoin (BTC)",
  Ethereum: "Ethereum (ETH)",
  "Tether (ERC20)": "Tether (ERC20) (USDT)",
  Monero: "Monero (XMR)",
  "Bitcoin (BEP2)": "Bitcoin (BEP2)",
  "Bitcoin (BEP20)": "Bitcoin (BEP20)",
  "Bitcoin (Lightning)": "Bitcoin (Lightning)",
  BitTorrent: "BitTorrent (BTT)",
  "PancakeSwap (BEP20)": "PancakeSwap (BEP20)",
  "DAI (BEP20)": "DAI (BEP20)",
  "DAI (ERC20)": "DAI (ERC20)",
  "DAI (Polygon)": "DAI (Polygon)",
  Dash: "Dash (DASH)",
  Dogecoin: "Dogecoin (DOGE)",
  Polkadot: "Polkadot (DOT)",
  EOS: "EOS (EOS)",
  "Ethereum Classic": "Ethereum Classic (ETC)",
  "Ethereum (Arbitrum)": "Ethereum (Arbitrum)",
  "Ethereum (BEP2)": "Ethereum (BEP2)",
  "Ethereum (BEP20)": "Ethereum (BEP20)",
  "Wrapped ETH (ERC20)": "Wrapped ETH (ERC20)",
  Fantom: "Fantom (FTM)",
  "KuCoin Token": "KuCoin Token (KCS)",
  "Chainlink (ERC20)": "Chainlink (ERC20) (LINK)",
  "Decentraland (ERC20)": "Decentraland (ERC20) (MANA)",
  Polygon: "Polygon (MATIC)",
  "Polygon (ERC20)": "Polygon (ERC20)",
  "Maker (ERC20)": "Maker (ERC20) (MKR)",
  "PAX Gold (ERC20)": "Pax Gold (ERC20) (USDP)",
  "SHIBA INU (ERC20)": "SHIBA INU (ERC20)",
  "SHIBA INU (BEP20)": "SHIBA INU (BEP20)",
  Solana: "Solana (SOL)",
  "Wrapped SOL (Solana)": "Wrapped SOL (SOL) (WSOL)",
  Toncoin: "Toncoin (TON)",
  Tron: "Tron (TRX)",
  "TrueUSD (ERC20)": "TrueUSD (ERC20) (TUSD)",
  "Trust Wallet Token (BEP2)": "Trust Wallet Token (BEP2)",
  "Trust Wallet Token (BEP20)": "Trust Wallet Token (BEP20)",
  "USD Coin (BEP20)": "USD Coin (BEP20) (USDC)",
  "USD Coin (ERC20)": "USD Coin (ERC20)",
  "USD Coin (Polygon)": "USD Coin (Polygon)", // под вопросом во время тестов было техническое обслуживание
  "USD Coin (Solana)": "USD Coin (Solana)",
  "USD Coin (TRC20)": "USD Coin (TRC20)",
  "USD Coin PoS (Polygon)": "USD Coin PoS (Polygon)", // под вопросом во время тестов было техническое обслуживание
  "Pax Dollar (ERC20)": "Pax Dollar (ERC20) (USDP)",
  "Tether (Arbitrum)": "Tether (Arbitrum)",
  "Tether (BEP2)": "Tether (BEP2)",
  "Tether (BEP20)": "Tether (BEP20)",
  "Tether (Polygon)": "Tether (Polygon)",
  "Tether (Solana)": "Tether (Solana)",
  "Tether (TRC20)": "Tether (TRC20)",
  VeChain: "VeChain (VET)",
  "Stellar Lumens (XLM)": "Stellar Lumens (XLM)", // под вопросом во время тестов было техническое обслуживание
  Ripple: "Ripple (XRP)",
  Tezos: "Tezos (XTZ)",
  Zcash: "Zcash (ZEC)",
  "0x (ERC20)": "0x (ERC20)",
};

export const currencies = [
  {
    code: "AAVEETH",
    coin: "AAVE",
    network: "ETH",
    priority: 0,
    name: "Aave (ERC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/aaveeth.svg",
    color: "#c673d4",
  },
  {
    code: "ADA",
    coin: "ADA",
    network: "ADA",
    priority: 0,
    name: "Cardano",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/ada.svg",
    color: "#ffffff",
  },
  {
    code: "ADABNB",
    coin: "ADA",
    network: "BNB",
    priority: 0,
    name: "Cardano (BEP2)",
    recv: 1,
    send: 1,
    tag: "MEMO",
    logo: "https://fixedfloat.com/assets/images/coins/svg/adabnb.svg",
    color: "#ffffff",
  },
  {
    code: "ADABSC",
    coin: "ADA",
    network: "BSC",
    priority: 0,
    name: "Cardano (BEP20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/adabsc.svg",
    color: "#ffffff",
  },
  {
    code: "ATOM",
    coin: "ATOM",
    network: "ATOM",
    priority: 0,
    name: "Cosmos",
    recv: 1,
    send: 1,
    tag: "MEMO",
    logo: "https://fixedfloat.com/assets/images/coins/svg/atom.svg",
    color: "#e6e6e6",
  },
  {
    code: "AVAX",
    coin: "AVAX",
    network: "AVAXC",
    priority: 0,
    name: "Avalanche (C-Chain)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/avax.svg",
    color: "#ca443c",
  },
  {
    code: "BAT",
    coin: "BAT",
    network: "ETH",
    priority: 0,
    name: "Basic Attention (ERC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/bat.svg",
    color: "#ff0649",
  },
  {
    code: "BCH",
    coin: "BCH",
    network: "BCH",
    priority: 0,
    name: "Bitcoin Cash",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/bch.svg",
    color: "#85c459",
  },
  {
    code: "BNB",
    coin: "BNB",
    network: "BNB",
    priority: 0,
    name: "BNB Beacon Chain (BEP2)",
    recv: 1,
    send: 1,
    tag: "MEMO",
    logo: "https://fixedfloat.com/assets/images/coins/svg/bnb.svg",
    color: "#ffcc4f",
  },
  {
    code: "BSC",
    coin: "BNB",
    network: "BSC",
    priority: 0,
    name: "BNB Smart Chain (BEP20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/bsc.svg",
    color: "#ffcc4f",
  },
  {
    code: "WBNBBSC",
    coin: "BNB",
    network: "BSC",
    priority: 0,
    name: "Wrapped BNB (BEP20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/wbnbbsc.svg",
    color: "#ffcc4f",
  },
  {
    code: "BTC",
    coin: "BTC",
    network: "BTC",
    priority: 5,
    name: "Bitcoin",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/btc.svg",
    color: "#f7931a",
  },
  {
    code: "BTCBNB",
    coin: "BTC",
    network: "BNB",
    priority: 0,
    name: "Bitcoin (BEP2)",
    recv: 1,
    send: 1,
    tag: "MEMO",
    logo: "https://fixedfloat.com/assets/images/coins/svg/btcbnb.svg",
    color: "#f7931a",
  },
  {
    code: "BTCBSC",
    coin: "BTC",
    network: "BSC",
    priority: 0,
    name: "Bitcoin (BEP20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/btcbsc.svg",
    color: "#f7931a",
  },
  {
    code: "BTCLN",
    coin: "BTC",
    network: "LN",
    priority: 0,
    name: "Bitcoin (Lightning)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/btcln.svg",
    color: "#9157ff",
  },
  {
    code: "BTT",
    coin: "BTT",
    network: "TRX",
    priority: 0,
    name: "BitTorrent",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/btt_dark.svg",
    color: "#ffffff",
  },
  {
    code: "CAKE",
    coin: "CAKE",
    network: "BSC",
    priority: 0,
    name: "PancakeSwap (BEP20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/cake.svg",
    color: "#43d2da",
  },
  {
    code: "DAIBSC",
    coin: "DAI",
    network: "BSC",
    priority: 0,
    name: "DAI (BEP20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/daibsc.svg",
    color: "#f4b731",
  },
  {
    code: "DAIETH",
    coin: "DAI",
    network: "ETH",
    priority: 0,
    name: "DAI (ERC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/daieth.svg",
    color: "#f4b731",
  },
  {
    code: "DAIMATIC",
    coin: "DAI",
    network: "MATIC",
    priority: 0,
    name: "DAI (Polygon)",
    recv: 0,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/daimatic.svg",
    color: "#f4b731",
  },
  {
    code: "DASH",
    coin: "DASH",
    network: "DASH",
    priority: 0,
    name: "Dash",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/dash.svg",
    color: "#008ce3",
  },
  {
    code: "DOGE",
    coin: "DOGE",
    network: "DOGE",
    priority: 0,
    name: "Dogecoin",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/doge.svg",
    color: "#ba9f33",
  },
  {
    code: "DOT",
    coin: "DOT",
    network: "DOT",
    priority: 0,
    name: "Polkadot",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/dot.svg",
    color: "#e6007a",
  },
  {
    code: "EOS",
    coin: "EOS",
    network: "EOS",
    priority: 0,
    name: "EOS",
    recv: 1,
    send: 1,
    tag: "MEMO",
    logo: "https://fixedfloat.com/assets/images/coins/svg/eos.svg",
    color: "#8997d8",
  },
  {
    code: "ETC",
    coin: "ETC",
    network: "ETC",
    priority: 0,
    name: "Ethereum Classic",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/etc.svg",
    color: "#8cbd9b",
  },
  {
    code: "ETH",
    coin: "ETH",
    network: "ETH",
    priority: 4,
    name: "Ethereum",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/eth.svg",
    color: "#ffffff",
  },
  {
    code: "ETHARBITRUM",
    coin: "ETH",
    network: "ARBITRUM",
    priority: 0,
    name: "Ethereum (Arbitrum)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/etharbitrum.svg",
    color: "#ffffff",
  },
  {
    code: "ETHBNB",
    coin: "ETH",
    network: "BNB",
    priority: 0,
    name: "Ethereum (BEP2)",
    recv: 1,
    send: 1,
    tag: "MEMO",
    logo: "https://fixedfloat.com/assets/images/coins/svg/ethbnb.svg",
    color: "#ffffff",
  },
  {
    code: "ETHBSC",
    coin: "ETH",
    network: "BSC",
    priority: 0,
    name: "Ethereum (BEP20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/ethbsc.svg",
    color: "#ffffff",
  },
  {
    code: "WETHETH",
    coin: "ETH",
    network: "ETH",
    priority: 0,
    name: "Wrapped ETH (ERC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/wetheth.svg",
    color: "#ffffff",
  },
  {
    code: "FTM",
    coin: "FTM",
    network: "FTM",
    priority: 0,
    name: "Fantom",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/ftm.svg",
    color: "#3f7fff",
  },
  {
    code: "KCS",
    coin: "KCS",
    network: "KCC",
    priority: 0,
    name: "KuCoin Token",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/kcs.svg",
    color: "#41e6af",
  },
  {
    code: "LINK",
    coin: "LINK",
    network: "ETH",
    priority: 0,
    name: "Chainlink (ERC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/link.svg",
    color: "#3f6ff3",
  },
  {
    code: "LTC",
    coin: "LTC",
    network: "LTC",
    priority: 3,
    name: "Litecoin",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/ltc.svg",
    color: "#497ed1",
  },
  {
    code: "MANAETH",
    coin: "MANA",
    network: "ETH",
    priority: 0,
    name: "Decentraland (ERC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/manaeth.svg",
    color: "#ff6947",
  },
  {
    code: "MATIC",
    coin: "MATIC",
    network: "MATIC",
    priority: 0,
    name: "Polygon",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/matic.svg",
    color: "#8247e5",
  },
  {
    code: "MATICETH",
    coin: "MATIC",
    network: "ETH",
    priority: 0,
    name: "Polygon (ERC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/maticeth.svg",
    color: "#8247e5",
  },
  {
    code: "MKR",
    coin: "MKR",
    network: "ETH",
    priority: 0,
    name: "Maker (ERC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/mkr.svg",
    color: "#10bc99",
  },
  {
    code: "PAXGETH",
    coin: "PAXG",
    network: "ETH",
    priority: 0,
    name: "PAX Gold (ERC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/paxgeth.svg",
    color: "#ede70a",
  },
  {
    code: "SHIB",
    coin: "SHIB",
    network: "ETH",
    priority: 0,
    name: "SHIBA INU (ERC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/shib.svg",
    color: "#f4a816",
  },
  {
    code: "SHIBBSC",
    coin: "SHIB",
    network: "BSC",
    priority: 0,
    name: "SHIBA INU (BEP20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/shibbsc.svg",
    color: "#f4a816",
  },
  {
    code: "SOL",
    coin: "SOL",
    network: "SOL",
    priority: 0,
    name: "Solana",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/sol.svg",
    color: "#a364fc",
  },
  {
    code: "WSOL",
    coin: "SOL",
    network: "SOL",
    priority: 0,
    name: "Wrapped SOL (Solana)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/wsol.svg",
    color: "#ffffff",
  },
  {
    code: "TON",
    coin: "TON",
    network: "TON",
    priority: 0,
    name: "Toncoin",
    recv: 1,
    send: 1,
    tag: "MEMO/Comment",
    logo: "https://fixedfloat.com/assets/images/coins/svg/ton.svg",
    color: "#0188cc",
  },
  {
    code: "TRX",
    coin: "TRX",
    network: "TRX",
    priority: 0,
    name: "Tron",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/trx.svg",
    color: "#ff2e4b",
  },
  {
    code: "TUSD",
    coin: "TUSD",
    network: "ETH",
    priority: 0,
    name: "TrueUSD (ERC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/tusd.svg",
    color: "#5dc9bc",
  },
  {
    code: "TWT",
    coin: "TWT",
    network: "BNB",
    priority: 0,
    name: "Trust Wallet Token (BEP2)",
    recv: 1,
    send: 1,
    tag: "MEMO",
    logo: "https://fixedfloat.com/assets/images/coins/svg/twt.svg",
    color: "#478dd7",
  },
  {
    code: "TWTBSC",
    coin: "TWT",
    network: "BSC",
    priority: 0,
    name: "Trust Wallet Token (BEP20)",
    recv: 1,
    send: 1,
    tag: "MEMO",
    logo: "https://fixedfloat.com/assets/images/coins/svg/twtbsc.svg",
    color: "#478dd7",
  },
  {
    code: "USDCBSC",
    coin: "USDC",
    network: "BSC",
    priority: 0,
    name: "USD Coin (BEP20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/usdcbsc.svg",
    color: "#2775ca",
  },
  {
    code: "USDCETH",
    coin: "USDC",
    network: "ETH",
    priority: 0,
    name: "USD Coin (ERC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/usdceth.svg",
    color: "#2775ca",
  },
  {
    code: "USDCMATIC",
    coin: "USDC",
    network: "MATIC",
    priority: 0,
    name: "USD Coin (Polygon)",
    recv: 0,
    send: 0,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/usdcmatic.svg",
    color: "#2775ca",
  },
  {
    code: "USDCSOL",
    coin: "USDC",
    network: "SOL",
    priority: 0,
    name: "USD Coin (Solana)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/usdcsol.svg",
    color: "#2775ca",
  },
  {
    code: "USDCTRC",
    coin: "USDC",
    network: "TRX",
    priority: 0,
    name: "USD Coin (TRC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/usdctrc.svg",
    color: "#2775ca",
  },
  {
    code: "USDCeMATIC",
    coin: "USDCe",
    network: "MATIC",
    priority: 0,
    name: "USD Coin PoS (Polygon)",
    recv: 0,
    send: 0,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/usdcematic.svg",
    color: "#ffffff",
  },
  {
    code: "USDP",
    coin: "USDP",
    network: "ETH",
    priority: 0,
    name: "Pax Dollar (ERC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/usdp.svg",
    color: "#c7e432",
  },
  {
    code: "USDT",
    coin: "USDT",
    network: "ETH",
    priority: 4,
    name: "Tether (ERC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/usdt.svg",
    color: "#53ae94",
  },
  {
    code: "USDTARBITRUM",
    coin: "USDT",
    network: "ARBITRUM",
    priority: 0,
    name: "Tether (Arbitrum)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/usdtarbitrum.svg",
    color: "#ffffff",
  },
  {
    code: "USDTBNB",
    coin: "USDT",
    network: "BNB",
    priority: 0,
    name: "Tether (BEP2)",
    recv: 1,
    send: 0,
    tag: "MEMO",
    logo: "https://fixedfloat.com/assets/images/coins/svg/usdtbnb.svg",
    color: "#53ae94",
  },
  {
    code: "USDTBSC",
    coin: "USDT",
    network: "BSC",
    priority: 0,
    name: "Tether (BEP20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/usdtbsc.svg",
    color: "#53ae94",
  },
  {
    code: "USDTMATIC",
    coin: "USDT",
    network: "MATIC",
    priority: 0,
    name: "Tether (Polygon)",
    recv: 0,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/usdtmatic.svg",
    color: "#53ae94",
  },
  {
    code: "USDTSOL",
    coin: "USDT",
    network: "SOL",
    priority: 0,
    name: "Tether (Solana)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/usdtsol.svg",
    color: "#53ae94",
  },
  {
    code: "USDTTRC",
    coin: "USDT",
    network: "TRX",
    priority: 0,
    name: "Tether (TRC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/usdttrc.svg",
    color: "#53ae94",
  },
  {
    code: "VET",
    coin: "VET",
    network: "VET",
    priority: 0,
    name: "VeChain",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/vet.svg",
    color: "#29acf6",
  },
  {
    code: "XLM",
    coin: "XLM",
    network: "XLM",
    priority: 0,
    name: "Stellar Lumens",
    recv: 0,
    send: 0,
    tag: "MEMO",
    logo: "https://fixedfloat.com/assets/images/coins/svg/xlm.svg",
    color: "#ffffff",
  },
  {
    code: "XMR",
    coin: "XMR",
    network: "XMR",
    priority: 2,
    name: "Monero",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/xmr.svg",
    color: "#e06b1a",
  },
  {
    code: "XRP",
    coin: "XRP",
    network: "XRP",
    priority: 0,
    name: "Ripple",
    recv: 1,
    send: 1,
    tag: "Destination tag",
    logo: "https://fixedfloat.com/assets/images/coins/svg/xrp.svg",
    color: "#0c89ca",
  },
  {
    code: "XTZ",
    coin: "XTZ",
    network: "XTZ",
    priority: 0,
    name: "Tezos",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/xtz.svg",
    color: "#4284ff",
  },
  {
    code: "ZEC",
    coin: "ZEC",
    network: "ZEC",
    priority: 0,
    name: "Zcash",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/zec.svg",
    color: "#ffbf20",
  },
  {
    code: "ZRX",
    coin: "ZRX",
    network: "ETH",
    priority: 0,
    name: "0x (ERC20)",
    recv: 1,
    send: 1,
    tag: null,
    logo: "https://fixedfloat.com/assets/images/coins/svg/zrx.svg",
    color: "#ffffff",
  },
];

export const language = {
  ru: {
    header: {
      logoAlt: "логотип",
      burgerAlt: "меню",
    },
    navbar: {
      aboutUs: "О нас",
      blog: "Блог",
      faq: "FAQ",
      support: "Поддержка",
    },
    accountMenu: {
      title: "Аккаунт",
      dropdown: {
        personal: "Пеосональные данные",
        history: "История обменов",
        adressBook: "Адресная книга",
        partner: "Партнерская программа",
        moneyBack: "Выводы средств",
        apiManagement: "API management",
        exit: "Выйти",
        iconAlt: "иконка персональных данных",
      },
    },
    languageMenu: {
      iconAlt: "иконка персональных данных",
    },
    auth: {
      login: "Вход",
      register: "Регистрация",
    },
    footer: {
      instagramAlt: "инстаграм",
      telegramAlt: "телеграм",
      redditAlt: "реддит",
      mediumAlt: "медиум",
      bcAlt: "бц",
      starAlt: "стар",
      copyright: "Все права защищены",
      aboutUs: "О нас",
      brand: "Наш бренд",
      terms: "Условия ипользования",
      privacy: "Политика конфиденциальности",
      titlePopular: "Популярные",
      blog: "Блог",
      faq: "FAQ",
      support: "Поддержка",
      api: "API",
      logoAlt: "логотип",
    },
    intro: {
      title: "Моментальный обмен криптовалют",
      benefitsTitle: "Нам доверяют с 2018 года",
      fastCard: {
        title: "Быстро",
        description:
          "Максимальная скорость обмена за счет полной автоматизации",
      },
      comfortCard: {
        title: "Удобно",
        description:
          "Выбирайте оптимальную стратегию и совершайте выгодные обмены",
      },
      profitCard: {
        title: "Выгодно",
        description: "Лучшие обменные курсы и минимальные комиссии",
      },
    },
    support: {
      title: "Поддержка",
      help: "Нужна помощь? Мы готовы помочь вам 24 часа, 7 дней в неделю.",
      timeShort: "Время ответа 10 минут",
      timeLong: "Время ответа 24 часа",
      write: "Написать",
      socialAlt: "иконка соцсети",
    },
    order: {
      title: "Тип заказа",
      rules: "Используя сайт и создавая обмен, вы соглашаетесь",
      terms: "Условиями использования",
      privacy: "Политикой конфиденциальности",
      wrongError: "Что-то пошло не так",
      priceError: "Запрос на получение цены временны недоступен",
      blockedError: "Запросы временно заблокированы. Попробуйте позже.",
      fixed: "Фиксированный (1.0%)",
      float: "Плавающий (0.5%)",
      send: "Отправляете",
      recieve: "Получаете",
      switchAlt: "кнопка переключить",
      warningAlt: "иконка предупреждения",
      maxSum: "Максимальная",
      minSum: "Минимальная",
      forExchange: "сумма для обмена",
      start: "Начать обмен",
    },
    transactions: {
      title: "Последние транзакции",
      coinAlt: "иконка криптовалюты",
      arrowAlt: "иконка стрелки",
      timeAlt: "иконка времени",
    },
    faqSection: {
      title: "FAQ",
      orderQA: {
        question: "Как отследить мой заказ?",
        answer:
          "Тремя способами: в реальном времени на нашем сайте, через подписку на email уведомления или путем просмотра транзакций в блокчейн по ссылкам из вашего заказа.",
      },
      trustQA: {
        question: "Почему я могу вам доверять?",
        answer:
          "Никакой регистрации и удержания ваших средств, все обмены проходят с максимальной скоростью и в полностью автоматическом режиме.",
      },
      feeQA: {
        question: "Есть ли скрытые комиссии?",
        answer:
          "Честность — наш главный приоритет, поэтому все наши комиссии максимально прозрачны: • 1% за фиксированный курс • 0.5% если вы выбираете плавающий курс",
      },
      toFaqPage: "Перейти на страницу FAQ",
      iconAlt: "иконка переключения секции FAQ",
    },
    news: {
      title: "Новости",
      leftArrowAlt: "левая стрелка",
      rightArrowAlt: "правая стрелка",
      readAll: "Читать все новости",
      read: "Читать",
      readAlso: "Читайте также",
      imgAlt: "Картинка к новости",
    },
    instructions: {
      title: "Руководства и инструкции",
      leftArrowAlt: "левая стрелка",
      rightArrowAlt: "правая стрелка",
      readAll: "Читать все",
      read: "Читать",
    },
    aboutUsPage: {
      title: "О нас",
      text: "CoinCharger был запущен командой блокчейн специалистов c большим опытом в разработке многофункциональных веб-приложений. Нас объединяет идея создания лучшей в своем классе платформы обмена цифровых активов, отвечающей всем потребностям криптосообщества.",
      advantagesTitle: "Преимущества",
      easyAdvantage: {
        text: "Максимальная простота совершения обмена и возможность выбора стратегии, позволят Вам совершать выгодный обмен",
        iconAlt: "иконка преимущества",
      },
      safeAdvantage: {
        text: "Мы обеспечиваем безопасный обмен. Вы ничем не рискуете и сразу видите размер комиссии",
        iconAlt: "иконка преимущества",
      },
      fastAdvantage: {
        text: "Вы можете совершать обмен с максимальной скоростью на любых устройствах",
        iconAlt: "иконка преимущества",
      },
      missionTitle: "Миссия",
      missionText:
        "Наша миссия состоит в том, чтобы упростить процесс обмена с помощью практичных и масштабируемых решений, которые позволят криптоэкономике работать для наших пользователей.",
      missionImageAlt: "картинка миссии",
      backgroundImageAlt: "картинка фона",
      coinsTitle: "Поддерживаемые криптовалюты",
      coinsAlt: "иконка криптовалюты",
      logoAlt: "логотип",
    },
    accountPage: {
      infoTitle: "Информация об аккаунте",
      timeText: {
        regDate: "Дата регистрации",
        lastVisit: "Последний визит",
        secondsAgo: "несколько секунд назад",
      },
      emailStatusesText: {
        notAproved: "Не подтвержден",
        aprove: "Подтвердить",
        change: "Изменить",
      },
      password: "Пароль",
      notification: "Получать уведомления о изменении статуса заказа на почту",
      address: "Для получения уведомлений подтвердите адрес электронной почты",
      exchangeHistory: "История обменов",
      noOrder: "Вы еще не создавали заказов.",
      firstOrder: "Создайте свой первый заказ",
      createOrder: "Создать заказ",
      activeOrder: "Активные заказы",
      doneOrder: "Выполненные заказы",
      waiting: "Ожидается",
      done: "Выполнен",
      table: {
        id: "Order ID",
        time: "Время",
        send: "Отправляете",
        receive: "Получите",
        status: "Статус",
        noData: "Нет данных",
        show: "Показывать по",
        record: "записей",
        address: "Адрес назначения",
      },
      addressBook: {
        title: "Адресная книга",
        date: "Дата",
        network: "Сеть",
        coin: "Монета",
        address: "Адрес",
        noData: "Нет данных",
      },
      partner: {
        stats: "Партнерская статистика",
        payouts: "Выводы средств",
        balance: "Расчетный баланс",
        waiting: "В ожидании выплаты",
        min: "Минимум для выплаты",
        link: "Партнерский код и ссылка",
        code: "Добавить новый код",
        currencyLink: "Ссылка с выбором валют",
        create: "Создать",
        placeholder: "Введите название или тикер",
        popCurrencies: "Популярные валюты",
        allCurrencies: "Все валюты",
        codeNumber: "Код",
        forExchange: "за обмен по ссылке",
        simpleLink: "Простая ссылка",
        partnerOrders: "Партнерские заказы",
        date: "Дата",
        order: "Заказ",
        profit: "Профит",
        payed: "Оплачен",
        noData: "Нет данных",
      },
      moneyBack: {
        payout: "Вывод",
        earnd: "Заработано",
        lessMinSum: "Меньше минимальной суммы для вывода в",
        your: "Ваш",
        address: "адрес",
        payoutAmount: "Вывод средств",
        lastPayoutAmount: "Последний вывод средств",
      },
      apiManagement: {
        title: "Генерация API key",
        getKeyText: "Получите ключ, чтобы пользоваться API",
        oneKeyText:
          "Вы можете иметь только один API ключ на аккаунт. Если у вас уже есть другой ключ, он будет заблокирован.",
        generateText: "Сгенерировать",
        apiManagement: "API management",
      },
    },
    blogPage: {
      title: "Блог",
      lastText: "Последние",
      instructions: "Руководства",
      news: "Новости",
      aboutCoins: "О криптовалютах",
      exchangeInstructions: "Инструкции по обмену",
      usefulInstructions: "Полезные инструкции",
    },
    faqPage: {
      title: "FAQ",
      aboutChange: "Об обмене",
      aboutOrder: "О заказе",
      about: "О",
      faqWhat: {
        title: "Что такое CoinCharger?",
        text: "CoinCharger — это полностью автоматизированный сервис для обмена криптовалют и токенов на выгодных условиях. CoinCharger не является кастодиальным. Обмен происходит сразу после получения монет и необходимого количества подтверждений сети. CoinCharger был запущен в 2018 году командой блокчейн специалистов c большим опытом в разработке многофункциональных веб-приложений. Стремясь предоставлять удобную платформу для обменов криптовалют, CoinCharger постоянно активно развивается и совершенстуется для своих клиентов.",
      },
      faqWhy: {
        title:
          "Почему CoinCharger это самый удобный способ обменять криптовалюту?",
        text: "Нашим главным приоритетом является предоставление высококачественного сервиса для обмена криптовалют с помощью наших уникальных решений, которые не ставят под угрозу скорость и безопасность.",
        economy:
          "Экономьте ваши деньги.Совершайте обмен по самым выгодным курсам с прозрачной комиссией. У нас всегда есть лучшее предложение.",
        economyTime:
          "Экономьте ваше время.Наш процессинг полностью автоматизирован, что позволяет проводить транзакции с максимальной скоростью.",
        easy: "Совершайте обмен без сложностей.Никакой регистрации и ненужных деталей, это просто как 1-2-3.",
        change:
          "Меняйте на любых устройствах.CoinCharger спроектирован для производительности и великолепно работает на всех типах устройств.",
      },
      faqTrust: {
        title: "Почему я могу доверять CoinCharger?",
        text: "За 4 года CoinCharger завоевал доверие более миллиона пользователей со всего мира. Мы уважаем анонимность и безопасность наших клиентов — для совершение обменов не требуется оставлять какие-либо данные. CoinCharger работает полностью автоматически, а поддержка доступна в Live Chat кругослуточно и без выходных. Лояльность наших клиентов в полной мере отражена на популярных площадках Trustpilot и BestChange.",
      },
      faqHow: {
        title: "Как совершить обмен?",
        text: "Для примера представим, что вам нужно обменять Bitcoin на Ethereum.",
        stepOne:
          'Шаг 1. На главной странице, в поле "Отправляете", выберите Bitcoin и введите сумму, которую вы хотите обменять.',
        stepTwo:
          'Шаг 2. Выберите фиксированный или плавающий курс, введите или отсканируйте адрес на который вы хотите получить Ethereum, и нажмите кнопку "Начать обмен".',
        stepThree:
          "Шаг 3. Отправьте Bitcoin на кошелек указанный на странице заказа и после получения нескольких подтверждений сети, Ethereum моментально будет отправлен на адрес, который вы указали в Шаге 2. Также доступно подробное руководство по созданию заказа на CoinCharger",
      },
      faqTrack: {
        title: "Как отследить мой заказ?",
        text: "Тремя способами: в реальном времени на нашем сайте, через подписку на email уведомления или путем просмотра транзакций в блокчейн по ссылкам из вашего заказа.",
      },
      inSocial: "в социальных сетях",
      onForums: "на форумах",
    },
    notFoundPageText: "Страница не найдена. Извините",
    sendingPage: {
      emailPopup: {
        title: "Email уведомление о статусе заказа",
        text: "Введите свой действующий email для получения уведомлений об изменении статуса этого заказа. Любые изменения данных заказа возможны только через подтверждения с email указанного в заказе",
        noMore: "Больше не показывать это уведомление",
        accept: "Подтвердить",
        decline: "Отказаться",
      },
      xrpPopup: {
        title: "Внимание",
        text: "Отправка транзакции наш адрес без указания или с неправильным Destination tag может привести к потере средств!",
        noMore:
          "Я больше не хочу получать это уведомление и согласен с вышеперечисленным для всех последующих заказов",
        accept:
          "Я подтверждаю, что отправка средств без указания Destination tag может привезти к безвозвратной потере средств",
      },
      sendingTo: {
        sending: "Вы отправляете",
        recieve: "Вы получаете",
        arrowAlt: "иконка стрелки",
        coinAlt: "иконка монеты",
      },
      sendingAllert: {
        wait: "Ждём появления транзакции в сети блокчейн",
        address:
          "На адрес указанный в заказе, ещё не поступили средтсва. Мы осуществим обмен средств после поступления транзакции и получения необходимого количества подтверждений сети блокчейн.",
        status: "Ждём изменения статуса заказа",
        goTo: "После изменения статуса заказа перейдём к",
        exchange: "обмену",
        refund: "возврату",
        funds: "средств",
        soon: "Скоро мы осуществим возврат суммы вашего заказа",
        arrived:
          "Мы получили вашу транзакцию, после получения необходимого количества подтверждений сети блокчейн, возврат будет осуществлен на адрес",
        orderSoon: "Ваш заказ скоро будет выполнен",
        exchangeSoon:
          "Мы получили вашу транзакцию, после получениия необходимого количества подтверждений сети блокчейн, мы осуществим обмен средств",
        addressWait:
          "На адрес, указанный в заказе, ещё не поступили средства. Когда мы получим нужное количество подтверждений сети блокчейн, средства будут возвращены на адрес",
      },
      orderNumber: {
        number: "Номер заказа",
        time: "Времени осталось",
        status: "Статус заказа",
        type: "Тип курса",
        timeCreated: "Время создания",
        timeRecieved: "Время получения",
        timeDone: "Время выполнения",
        float: "Плавающий",
        fixed: "Фиксированный",
        answer: "Ответ пользователя",
        returned: "Возвращено",
        done: "Завершено",
        earned: "Получено",
        iconAlt: "иконка копировать",
        orderDone: "Заказ истёк",
      },
      sendingInfo: {
        address: " на адрес",
        imgAlt: "иконка копировать",
        memo: {
          field: "в поле",
          memo: "MEMO/Comment обязательно",
          specify: "укажите:",
        },
        course: {
          fix: "Курс будет зафиксирован после получения",
          aprove: "подтверждений сети",
        },
        attention: {
          attention: "Внимание!",
          change:
            "При изменении рыночного курса более чем на 1.2% до появления вашей транзакции в сети блокчейн, вам будет предложено сделать возврат средств или продолжить обмен по рыночному курсу.",
        },
        incomeAdress: "Адрес получения",
        send: "Отправьте ",
      },
      qr: {
        notGenerated: "QR еще не сгенерирован",
        qrAlt: "иконка QR",
        withSum: "С суммой",
        address: "Адрес",
      },
      loader: {
        waitAlt: "иконка ожидания обмена",
        waitFunds: "Ожидаем поступления средств",
        loadAlt: "иконка загрузки",
        waitAprove: "Ожидаем подтверждения сети",
        waitAnswer: "Ожидаем ответа пользователя",
        exchangeInProgress: "Выполняем обмен",
        done: "Обмен завершен",
      },
      know: {
        title: "Что нужно знать?",
        iconAlt: "иконка",
      },
      notifications: {
        title: "Уведомления о статусе заказа",
        email:
          "Введите свой email, если хотите получать уведомления о изменении статуса этого заказа",
        placeholder: "Ваш email",
        enable: "Включить уведомления",
        imgAlt: "картинка банкинга",
      },
      txInfo: {
        look: "Посмотреть в блокчейн:",
        imgAlt: "иконка блокчейна",
        copyAlt: "иконка копировать",
        time: "Время отправки:",
        block: "Попадание в блок:",
        aprove: "Подтверждения:",
        sum: "Сумма:",
        fee: "Комиссия:",
        address: "Адрес получения",
        info: "Информация о возврате",
        title: "Информация о транзакции",
      },
      txDone: {
        hope: "Мы надеемся, что вам понравился наш сервис и будем рады, если вы поддержите нас отзывом",
        feedback: "Оставить отзыв",
        your: "Ваш",
        refund: "был возвращен",
        send: "был отправлен",
        address: "адрес",
      },
      info: "Информация об отправленной транзакции",
      infoRecieved: "Информация о принятой транзакции",
      expired: {
        sended:
          "Я отправил средства для обмена, но мой заказ истёк, что делать?",
        notRecieved:
          "Мы еще не получили вашу транзакцию, но вы можете сделать выбор, что сделать если средства по данному адресу поступят к нам на счет в течение 24 часов. Выберите 1 из вариантов:",
        notAdress: "Неверный адрес",
        continue: "Продолжить мой обмен (по текущему рыночному курсу)",
        return:
          "Совершить возврат суммы отправленной для обмена за вычетом комиссии сети",
        continueShort: "Продолжить обмен",
        receiveShort: "Сделать возврат",
      },
      error: {
        lessMin:
          "Вы отправили сумму меньше необходимой и меньше минимального лимита",
        moreMin:
          "Вы отправили сумму больше необходимой и больше минимального лимита",
        more: "Вы отправили сумму больше необходимой",
        less: "Вы отправили сумму меньше необходимой",
        after:
          "Ваши средства поступили после истечения времени на оплату заказа.",
        variant: "Выберите один из вариантов:",
        buffer:
          "Вам нужно дать браузеру разрешение на использование вашего буфера обмена",
        pasteAlt: "иконка вставить",
        cancelAlt: "иконка отменить",
        destination: "Destination tag",
        warningAlt: "иконка предупреждения",
      },
    },
    errors: {
      wrongError: "Что-то пошло не так",
      techError: "Технические работы на сервере",
      cciesError: "Запрос на получение валют временны недоступен",
    },
  },
  en: "English",
  ua: "Украинский",
  ge: "Грузинский",
  pl: "Польский",
};
