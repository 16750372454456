import { useMediaQuery } from "@uidotdev/usehooks";
import classNames from "classnames";
import { useState } from "react";
import { useSelector } from "react-redux";

import { DropdownListCoins } from "./DropdownListCoins";
import { processOrderErrors } from "../utils/helpers";

import warning from "../images/icons/warning.svg";
import { language } from "../utils/constants";

export const OrderItem = ({
  title,
  amount,
  stateCoin,
  setStateCoin,
  dropdownState,
  setDropdownState,
  setAmountCoin,
  nameCoinTo,
  setInputInSentCoin,
  which,
  float,
}) => {
  const ipadMini = useMediaQuery("only screen and (max-width : 1024px)");
  const miniOrder = useMediaQuery("only screen and (max-width : 610px)");
  const laptop = useMediaQuery("only screen and (min-width : 1024px)");

  const [isFocusInput, setFocusInput] = useState(false);

  const errors = useSelector((state) => state.creatingOrder.errors);
  const isFixed = useSelector((state) => state.isFixed);
  const orderFrom = useSelector((state) => state.creatingOrder.from);
  const currentCoin = useSelector((state) =>
    which === "FROM" ? state.creatingOrder.from : state.creatingOrder.to
  );
  const langCode = useSelector((state) => state.language);

  const errorsLimit =
    errors && errors.filter((err) => err.split("_")[0] === "LIMIT");
  const errorsWhich =
    errors && errors.filter((err) => err.split("_")[1] === which);

  return (
    <div
      className={classNames(
        "bg-white bg-opacity-20 flex flex-col items-center p-3 rounded-[24px] ",
        {
          "mt-2 w-full": ipadMini,
          "mt-2 w-full max-w-mobile-container": miniOrder,
          "w-[347px]": laptop,
        }
      )}
      onClick={() => setInputInSentCoin(which === "FROM" ? true : false)}
    >
      <h3 className="text-3xl w-full text-left">{title}</h3>
      {/* <OrderItemCoin /> */}
      <div
        className={classNames(
          "flex h-12 justify-between w-full mt-3 rounded-xl bg-input relative",
          {
            "z-10": dropdownState,
          }
        )}
      >
        {!dropdownState && (
          <div className="flex-grow">
            <input
              type="text"
              className={classNames(
                "w-full bg-transparent outline-none font-mono text-2xl py-2 pl-6 pr-1 text-[#D7DFFF] no-scrollbar",
                {
                  "text-[#d9d6ff80]": errorsWhich && errorsWhich.length > 0,
                }
              )}
              onFocus={() => {
                setFocusInput(true);
                setInputInSentCoin(which === "FROM" ? true : false);
              }}
              onBlur={(ev) => {
                setTimeout(() => {
                  setFocusInput(false);
                }, 1000);
              }}
              onChange={(ev) => {
                setAmountCoin(ev.target.value, which === "FROM" ? true : false);
              }}
              value={`${float ? "≈" : ""}${amount}`}
              maxLength={17}
            />
          </div>
        )}
        <div
          className={classNames("min-w-[125px]", {
            "w-full": dropdownState,
          })}
        >
          <DropdownListCoins
            dropdownState={dropdownState}
            setDropdownState={setDropdownState}
            selectName="main-sent-coin"
            stateCoin={stateCoin}
            setStateCoin={setStateCoin}
            amount={amount}
            which={which}
          />
        </div>

        {((errorsWhich && errorsWhich.length > 0) ||
          (errorsLimit && errorsLimit.length > 0 && which === "FROM")) &&
          !dropdownState && (
            <div className="absolute top-14 flex justify-between items-center px-3 py-1 bg-[#FF5454] w-[90%] mx-[5%] rounded-lg mb-2">
              <img src={warning} alt={language[langCode].order.warningAlt} />
              <p className="text-[#08035B]">
                {errorsWhich &&
                  errorsWhich.length > 0 &&
                  processOrderErrors(errorsWhich)}
                {errorsLimit &&
                  errorsLimit.length > 0 &&
                  errorsWhich &&
                  errorsWhich.length === 0 && (
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        setInputInSentCoin(true);
                        setAmountCoin(
                          Number(orderFrom.max) < amount
                            ? orderFrom.max
                            : orderFrom.min,
                          which === "FROM" ? true : false
                        );
                      }}
                    >
                      {`${
                        Number(orderFrom.max) < amount
                          ? language[langCode].order.maxSum
                          : language[langCode].order.maxSum
                      } ${language[langCode].order.forExchange} `}
                      <span className="underline">
                        {Number(orderFrom.max) < amount
                          ? orderFrom.max
                          : orderFrom.min}
                      </span>{" "}
                      <span>{orderFrom.code}</span>
                    </span>
                  )}
              </p>
            </div>
          )}
      </div>

      {!isFocusInput && (
        <div className="flex flex-row justify-between w-full">
          <p className="text-left text-base mt-2">{`1 ${currentCoin.code} ${
            isFixed ? "=" : "≈"
          } ${currentCoin.rate} ${nameCoinTo}`}</p>
          <p className="text-left text-base mt-2">{`${currentCoin.usd}$`}</p>
        </div>
      )}
      {isFocusInput && (
        <div className="flex flex-row justify-between w-full">
          <p
            className="text-left text-base mt-2 cursor-pointer"
            onClick={() =>
              setAmountCoin(currentCoin.min, which === "FROM" ? true : false)
            }
          >{`min: ${currentCoin.min}`}</p>
          <p
            className="text-left text-base mt-2 cursor-pointer"
            onClick={() =>
              setAmountCoin(currentCoin.max, which === "FROM" ? true : false)
            }
          >{`max: ${currentCoin.max}`}</p>
        </div>
      )}
    </div>
  );
};
