import { useMediaQuery } from "@uidotdev/usehooks";
import classNames from "classnames";
import { useState } from "react";
import { Link } from "react-router-dom";

import { blockchains, coinsRealNames } from "../utils/constants";

import squares from "../images/icons/squares.svg";
import blockchain from "../images/blockchain.png";

import { handleClickCopy } from "../utils/helpers";

import { language } from "../utils/constants";
import { useSelector } from "react-redux";

export const SendingTransactionBack = ({ order }) => {
  const iphone = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 744px)"
  );
  const macbook = useMediaQuery(
    "only screen and (min-width : 1024px) and (max-width : 1440px)"
  );
  const miniTop = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 1210px)"
  );
  const phone = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 585px)"
  );

  const [isTxIDCopied, setIsTxIDCopied] = useState(false);

  const langCode = useSelector((state) => state.language);

  const getStrData = (sec) => {
    const date = new Date(Number(sec) * 1000);

    const dateStr = date.toLocaleString("ru", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    const timeStr = date.toLocaleString("ru", {
      hour: "numeric",
      minute: "numeric",
    });

    return `${dateStr}, ${timeStr}`;
  };

  return (
    <div
      className={classNames(
        "flex flex-col bg-order rounded-3xl text-left  space-y-4 w-full",
        {
          "w-2/4": !miniTop,
          "w-full": miniTop,
          "text-xl p-6 space-y-4": phone,
          "p-8": !phone,
        }
      )}
    >
      <p className="text-2xl font-semibold min-h-[64px]">
        {language[langCode].sendingPage.txInfo.info}
      </p>
      <div>
        <p>TxID</p>
        <div
          className={classNames(
            "flex max-w-[550px] bg-sending-input rounded-lg px-4 py-2 items-center justify-between cursor-pointer",
            {
              "w-[370px]": macbook,
              "w-[300px]": iphone,
              "text-[#95FF54]": isTxIDCopied,
            }
          )}
          onClick={() => {
            handleClickCopy(order && order.back.tx.id, setIsTxIDCopied);
          }}
        >
          <p className="text-xl leading-6  overflow-hidden truncate">
            {order && order.back.tx.id}
          </p>
          <img
            className="cursor-pointer ml-2"
            src={squares}
            alt={language[langCode].sendingPage.txInfo.copyAlt}
          />
        </div>
      </div>
      <div>
        <p>{language[langCode].sendingPage.txInfo.look}</p>
        <ul className="flex flex-row space-x-2 mt-1">
          {/*TODO сюда нужно добавить еще изменение картинки в зависимости от блокчейна */}
          {blockchains.map((item, index) => {
            if (item.name === coinsRealNames[order.from.name]) {
              return item.txIds.map((link, linkIndex) => (
                <li key={linkIndex}>
                  <Link to={link.replace("{txID}", order.from.tx.id)}>
                    <img
                      src={blockchain}
                      alt={language[langCode].sendingPage.txInfo.imgAlt}
                    />
                  </Link>
                </li>
              ));
            }
            return null;
          })}
        </ul>
      </div>
      <div>
        <p>{language[langCode].sendingPage.txInfo.time}</p>
        <p className="text-blue-200">
          {order &&
            (order.back.tx.timeReg ? getStrData(order.back.tx.timeReg) : "-")}
        </p>
      </div>
      <div>
        <p>{language[langCode].sendingPage.txInfo.block}</p>
        <p className="text-blue-200">
          {order &&
            (order.back.tx.timeBlock
              ? getStrData(order.back.tx.timeBlock)
              : "-")}
        </p>
      </div>
      <div>
        <p>{language[langCode].sendingPage.txInfo.aprove}</p>
        <p
          className={classNames("text-blue-200", {
            "text-red-500": Number(order && order.back.tx.confirmations) <= 0,
            "text-lime-300": Number(order && order.back.tx.confirmations) > 0,
          })}
        >
          {order && order.back.tx.confirmations}
        </p>
      </div>
      <div>
        <p>{language[langCode].sendingPage.txInfo.sum}</p>
        <p className="text-blue-200">
          {order && `${order.back.tx.amount || 0} ${order.back.code}`}
        </p>
      </div>
      <div>
        <p>{language[langCode].sendingPage.txInfo.fee}</p>
        <p className="text-blue-200">
          {order && `${order.back.tx.fee || 0} ${order.back.code}`}
        </p>
      </div>
    </div>
  );
};
