// img
import warning from "../images/icons/warningRed.svg";
import warningBlue from "../images/icons/warning.svg";
import qr from "../images/icons/qr.svg";
import squares from "../images/icons/squares.svg";
import unselectedRadio from "../images/icons/unselectedRadio.svg";
import selectedRadio from "../images/icons/selectedRadio.svg";
import close from "../images/icons/close.svg";

// lib
import { useState } from "react";
import "@splidejs/react-splide/css/core";
import QrReader from "react-qr-scanner";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useMediaQuery } from "@uidotdev/usehooks";
import { api } from "../utils/api";

import { setOrder } from "../store/actions";
import { InputAddressCoin } from "./InputAddressCoin";
import { language } from "../utils/constants";

export const SendingError = () => {
  const iphone = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 744px)"
  );
  const desctop = useMediaQuery("only screen and (min-width : 1440px)");

  const [isReturn, setIsReturn] = useState(true);
  const [coinAddress, setCoinAddress] = useState("");
  const [invalidAddress, setInvalidAddress] = useState(false);
  const [openQR, setOpenQR] = useState(false);
  const order = useSelector((state) => state.order);
  const [memoTag, setMemoTag] = useState("");

  const previewStyle = {
    height: "100%",
    width: "80vw",
  };

  const isLessLimit =
    order &&
    order.emergency.status.includes("LESS") &&
    order.emergency.status.includes("LIMIT");
  const isMoreLimit =
    order &&
    order.emergency.status.includes("MORE") &&
    order.emergency.status.includes("LIMIT");

  const isMore =
    order &&
    order.emergency.status.includes("MORE") &&
    order.emergency.status.length === 1;
  const isLess =
    order &&
    order.emergency.status.includes("LESS") &&
    order.emergency.status.length === 1;
  const isExpired =
    order &&
    order.emergency.status.includes("EXPIRED") &&
    order.emergency.status.length === 1;

  const langCode = useSelector((state) => state.language);

  return (
    <div
      className={classNames("bg-order rounded-3xl w-full", {
        "px-12 py-10": !iphone,
        "px-4 py-10": iphone,
      })}
    >
      {isLessLimit && (
        <div
          className={classNames("flex items-start text-[32px] font-bold mb-6", {
            "text-xl": iphone,
          })}
        >
          <img
            className="mr-2 w-10 h-10 self-baseline"
            src={warning}
            alt={language[langCode].sendingPage.error.warningAlt}
          />
          <p className="text-left text-2xl font-semibold">
            {language[langCode].sendingPage.error.lessMin}
          </p>
        </div>
      )}
      {isMoreLimit && (
        <div
          className={classNames(
            "flex items-center text-[32px] font-bold mb-6",
            {
              "text-xl": iphone,
            }
          )}
        >
          <img
            className="mr-2 w-10 h-10 self-baseline"
            src={warning}
            alt="warning"
          />
          <p className="text-left text-2xl font-semibold">
            {language[langCode].sendingPage.error.moreMin}
          </p>
        </div>
      )}
      {isMore && (
        <div
          className={classNames(
            "flex items-center text-[32px] font-bold mb-6",
            {
              "text-xl": iphone,
            }
          )}
        >
          <img
            className="mr-2 w-10 h-10 self-baseline"
            src={warning}
            alt="warning"
          />
          <p className="text-left text-2xl font-semibold">
            {language[langCode].sendingPage.error.more}
          </p>
        </div>
      )}
      {isLess && (
        <div
          className={classNames(
            "flex items-center text-[32px] font-bold mb-6",
            {
              "text-xl": iphone,
            }
          )}
        >
          <img
            className="mr-2 w-10 h-10 self-baseline"
            src={warning}
            alt="warning"
          />
          <p className="text-left text-2xl font-semibold">
            {language[langCode].sendingPage.error.less}
          </p>
        </div>
      )}
      {isExpired && (
        <>
          <div
            className={classNames(
              "flex items-center text-[32px] font-bold mb-6",
              {
                "text-xl": iphone,
              }
            )}
          >
            <img
              className="mr-2 w-10 h-10 self-baseline"
              src={warning}
              alt="warning"
            />
            <p className="text-left text-2xl font-semibold">
              {language[langCode].sendingPage.error.after}
            </p>
          </div>
          <p
            className={classNames("text-left text-base mb-6", {
              " text-base": iphone,
            })}
          >
            {language[langCode].sendingPage.error.variant}
          </p>
        </>
      )}

      {(isExpired || isLess || isMore) && (
        <form
          className="flex flex-col items-start"
          onSubmit={(ev) => {
            ev.preventDefault();
            // SUBMIT
            isReturn
              ? api
                  .cancelOrder(
                    memoTag === ""
                      ? {
                          id: order.id,
                          token: order.token,
                          choice: "REFUND",
                          address: coinAddress,
                        }
                      : {
                          id: order.id,
                          token: order.token,
                          choice: "REFUND",
                          address: coinAddress,
                          tag: memoTag,
                        }
                  )
                  .then((res) => {
                    if (res.msg === "Invalid address") {
                      setInvalidAddress(
                        language[langCode].sendingPage.expired.notAdress
                      );
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  })
              : api
                  .cancelOrder({
                    id: order.id,
                    token: order.token,
                    choice: "EXCHANGE",
                  })
                  .then((res) => {
                    if (res.msg === "Invalid address") {
                      setInvalidAddress(
                        language[langCode].sendingPage.expired.notAdress
                      );
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
          }}
        >
          <label
            htmlFor="continue"
            className={classNames(
              "flex items-center text-left font-semibold mb-4",
              {
                "text-xl": !iphone,
                "text-base": iphone,
              }
            )}
            onClick={() => setIsReturn(false)}
          >
            <input
              className="hidden"
              type="radio"
              name="action"
              id="continue"
            />
            <img
              className="mr-2"
              src={isReturn ? unselectedRadio : selectedRadio}
              alt=""
            />
            {language[langCode].sendingPage.expired.continue}
          </label>
          <label
            htmlFor="return"
            className={classNames(
              "flex items-center text-left font-semibold mb-4",
              {
                "text-xl": !iphone,
                "text-base": iphone,
              }
            )}
            onClick={() => setIsReturn(true)}
          >
            <input className="hidden" type="radio" name="action" id="return" />
            <img
              className="mr-2"
              src={isReturn ? selectedRadio : unselectedRadio}
              alt=""
            />
            {language[langCode].sendingPage.expired.return}
          </label>

          {!isReturn && (
            <button
              className="bg-btns rounded-xl text-xl px-4 py-3 font-semibold"
              type="submit"
            >
              {language[langCode].sendingPage.expired.continueShort}
            </button>
          )}

          {isReturn && (
            <div
              className={classNames("flex gap-4 w-full justify-between", {
                "flex-col": !desctop,
              })}
            >
              <InputAddressCoin
                stateAddress={coinAddress}
                setStateAddress={setCoinAddress}
                stateMemo={memoTag}
                setStateMemo={setMemoTag}
                invalidAddress={invalidAddress}
                setInvalidAddress={setInvalidAddress}
                openQR={openQR}
                setOpenQR={setOpenQR}
              />
              <button
                className="self-start bg-btns rounded-xl text-xl px-4 py-3 font-semibold whitespace-nowrap"
                type="submit"
              >
                {language[langCode].sendingPage.expired.receiveShort}
              </button>
            </div>
          )}
        </form>
      )}

      {(isLessLimit || isMoreLimit) && (
        <form
          className="flex flex-col items-start"
          onSubmit={(ev) => {
            ev.preventDefault();
            // SUBMIT
            api
              .cancelOrder(
                memoTag === ""
                  ? {
                      id: order.id,
                      token: order.token,
                      choice: "REFUND",
                      address: coinAddress,
                    }
                  : {
                      id: order.id,
                      token: order.token,
                      choice: "REFUND",
                      address: coinAddress,
                      tag: memoTag,
                    }
              )
              .then((res) => {
                if (res.msg === "Invalid address") {
                  setInvalidAddress(
                    language[langCode].sendingPage.expired.notAdress
                  );
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        >
          <div
            className={classNames("flex gap-4 w-full justify-between", {
              "flex-col": !desctop,
            })}
          >
            <InputAddressCoin
              stateAddress={coinAddress}
              setStateAddress={setCoinAddress}
              stateMemo={memoTag}
              setStateMemo={setMemoTag}
              invalidAddress={invalidAddress}
              setInvalidAddress={setInvalidAddress}
              openQR={openQR}
              setOpenQR={setOpenQR}
            />
            <button
              className="self-start bg-btns rounded-xl text-xl px-4 py-3 font-semibold whitespace-nowrap"
              type="submit"
            >
              {language[langCode].sendingPage.expired.receiveShort}
            </button>
          </div>
        </form>
      )}

      {openQR && (
        <div className="fixed flex justify-center items-center top-0 left-0 w-screen h-screen bg-black z-20">
          <button
            className="absolute top-10 right-10"
            onClick={() => setOpenQR(false)}
          >
            <img src={close} alt="X" />
          </button>
          <QrReader
            delay={500}
            style={previewStyle}
            onError={(err) => {
              console.log(err);
            }}
            onScan={(data) => {
              if (data !== null) {
                setCoinAddress(data.text);
                setOpenQR(false);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};
