import notFoundImage from "../images/notfound/notfound.png";

import { useMediaQuery } from "@uidotdev/usehooks";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { language } from "../utils/constants";

export const NotFoundPage = () => {
  const phone = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 600px)"
  );

  const langCode = useSelector((state) => state.language);

  return (
    <div className="h-full flex flex-col justify-center items-center">
      <img
        className={classNames("w-[645px] h-[447px]", {
          "w-[645px] h-[447px] mt-[5%]": !phone,
          "w-[320px] h-[220px] mt-[10%]": phone,
        })}
        src={notFoundImage}
      />
      <h1
        className={classNames(" font-bold mt-6", {
          "text-5xl mb-[10%]": !phone,
          "text-3xl mb-[25%]": phone,
        })}
      >
        {language[langCode].notFoundPageText}
      </h1>
    </div>
  );
};
