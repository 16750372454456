import { Outlet } from "react-router-dom";
import { Header } from "./Header";
import { Footer } from "./Footer";

export const Layout = ({ isLoggedIn, setCoinSent, setCoinRecv }) => {
  return (
    <>
      <Header isLoggedIn={isLoggedIn} />
      <Outlet />
      <Footer setCoinSent={setCoinSent} setCoinRecv={setCoinRecv} />
    </>
  );
};
