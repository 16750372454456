import { useSelector } from "react-redux";
import { language } from "../utils/constants";

export const AuthMenu = () => {
  const langCode = useSelector((state) => state.language);
  return (
    <div className="flex flex-row items-center">
      <button className="text-xl cursor-pointer">
        {language[langCode].auth.login}
      </button>
      <button className="text-xl ml-4 py-3 px-4 bg-btns rounded-2xl cursor-pointer">
        {language[langCode].auth.register}
      </button>
    </div>
  );
};
