import fast from "../images/icons/fast.svg";
import comfort from "../images/icons/comfortable.svg";
import profit from "../images/icons/profitably.svg";
import classNames from "classnames";
import { useMediaQuery } from "@uidotdev/usehooks";
import { BenefitCard } from "./BenefitCard";
import { useSelector } from "react-redux";
import { language } from "../utils/constants";

export const BenefitCards = () => {
  const iphone = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 744px)"
  );
  const ipadMini = useMediaQuery(
    "only screen and (min-width : 744px) and (max-width : 1024px)"
  );
  const laptop = useMediaQuery(
    "only screen and (min-width : 1024px) and (max-width : 1280px)"
  );
  const desctop = useMediaQuery("only screen and (min-width : 1280px)");

  const langCode = useSelector((state) => state.language);

  return (
    <div
      className={classNames("flex flex-row items-center mt-8  mb-12", {
        "max-w-main-container": laptop,
        "max-w-screen-xl": desctop,
        "max-w-tablet-container flex-col": ipadMini,
        "flex-col max-w-tablet-container": iphone,
      })}
    >
      <BenefitCard
        imageSrc={fast}
        title={language[langCode].intro.fastCard.title}
        description={language[langCode].intro.fastCard.description}
      />
      <BenefitCard
        imageSrc={comfort}
        title={language[langCode].intro.comfortCard.title}
        description={language[langCode].intro.comfortCard.description}
      />
      <BenefitCard
        imageSrc={profit}
        title={language[langCode].intro.profitCard.title}
        description={language[langCode].intro.profitCard.description}
      />
    </div>
  );
};
