import { Link } from "react-router-dom";
import { FaqSeqtionItem } from "./FaqSeqtionItem";
import { useMediaQuery } from "@uidotdev/usehooks";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { language } from "../utils/constants";

export const FaqSection = () => {
  const iphone = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 744px)"
  );

  const langCode = useSelector((state) => {
    return state.language;
  });

  return (
    <section className="flex flex-col max-w-1328 self-center">
      <h2
        className={classNames(
          "text-center font-segoe bg-gradient-to-r from-[#FBE3F1] to-[#CAAEFF] bg-clip-text text-transparent mt-12 mb-10 self-center",
          {
            "text-2xl font-semibold": iphone,
            "text-5xl font-bold": !iphone,
          }
        )}
      >
        {language[langCode].faqSection.title}
      </h2>
      <FaqSeqtionItem
        question={language[langCode].faqSection.orderQA.question}
        answer={language[langCode].faqSection.orderQA.answer}
      />
      <FaqSeqtionItem
        question={language[langCode].faqSection.trustQA.question}
        answer={language[langCode].faqSection.trustQA.answer}
      />

      <FaqSeqtionItem
        question={language[langCode].faqSection.feeQA.question}
        answer={language[langCode].faqSection.feeQA.answer}
      />
      <Link
        className="self-right font-semibold text-xl text-right mt-6"
        to="/faq"
      >
        {language[langCode].faqSection.toFaqPage}
      </Link>
    </section>
  );
};
