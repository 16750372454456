import { useMediaQuery } from "@uidotdev/usehooks";
import classNames from "classnames";

import { useState } from "react";
import { useSelector } from "react-redux";
import squares from "../images/icons/squares.svg";
import { handleClickCopy, trimNumberCoin } from "../utils/helpers";
import { language } from "../utils/constants";

export const SendingInfo = () => {
  const miniTop = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 1210px)"
  );
  const phone = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 585px)"
  );

  const [isAddressSentCopied, setAddressSentCopied] = useState(false);
  const [isAddressRecCopied, setAddressRecCopied] = useState(false);
  const [isSummSentCopied, setSummSentCopied] = useState(false);
  const [isToTagMemoCopied, setToTagMemoCopied] = useState(false);
  const [isFromTagMemoCopied, setFromTagMemoCopied] = useState(false);
  const order = useSelector((state) => state.order);
  const isFixed = useSelector((state) => state.isFixed);
  const langCode = useSelector((state) => state.language);

  return (
    <div
      className={classNames(
        "flex flex-col bg-order rounded-3xl text-left justify-between",
        {
          "w-2/4": !miniTop,
          "w-full": miniTop,
          "text-xl p-6 space-y-4": phone,
          "p-8": !phone,
        }
      )}
    >
      <div>
        <p className="text-3xl font-semibold">
          {language[langCode].sendingPage.sendingInfo.send}
          {phone && <br />}
          <span
            className={classNames("font-mono cursor-pointer", {
              "text-[#95FF54]": isSummSentCopied,
              "text-blue-200": !isSummSentCopied,
            })}
            onClick={() =>
              handleClickCopy(
                trimNumberCoin(order.from.amount),
                setSummSentCopied
              )
            }
          >
            <span className="mr-2">{`${
              order && trimNumberCoin(order.from.amount)
            }`}</span>
            {`${order && order.from.code}`}
          </span>
          {phone && <br />} {language[langCode].sendingPage.sendingInfo.address}
        </p>
        <div
          className={classNames(
            "flex flex-row font-semibold space-x-2 mt-2 cursor-pointer",
            {
              "text-xl items-start": phone,
              "text-2xl items-center": !phone,
            }
          )}
          onClick={() =>
            handleClickCopy(order && order.from.address, setAddressSentCopied)
          }
        >
          <p
            className={classNames("break-all", {
              "text-[#95FF54]": isAddressSentCopied,
            })}
          >
            {order && order.from.address}
          </p>
          <img
            className="cursor-pointer"
            src={squares}
            alt={language[langCode].sendingPage.sendingInfo.imgAlt}
          />
        </div>
        {order.from.tag && (
          <p className="mt-2 text-xl font-semibold">
            {language[langCode].sendingPage.sendingInfo.memo.field}
            <span className="text-red-500 mx-2 text-2xl">
              {language[langCode].sendingPage.sendingInfo.memo.memo}
            </span>
            {language[langCode].sendingPage.sendingInfo.memo.specify}
            <span
              className={classNames("flex font-mono cursor-pointer text-2xl", {
                "text-blue-200": !isFromTagMemoCopied,
                "text-[#95FF54]": isFromTagMemoCopied,
              })}
              onClick={() =>
                handleClickCopy(order.from.tag, setFromTagMemoCopied)
              }
            >
              {order.from.tag}
              <img
                className="ml-1 cursor-pointer"
                src={squares}
                alt={language[langCode].sendingPage.sendingInfo.imgAlt}
              />
            </span>
          </p>
        )}
        {!isFixed && (
          <p className={classNames("text-blue-200 mt-4 text-base")}>
            {`${language[langCode].sendingPage.sendingInfo.course.fix} ${
              order && order.from.reqConfirmations
            } ${language[langCode].sendingPage.sendingInfo.course.aprove}`}
          </p>
        )}
        {isFixed && (
          <p className={classNames("text-blue-200 mt-4 text-base")}>
            <span className="text-white font-semibold">
              {language[langCode].sendingPage.sendingInfo.attention.attention}
            </span>{" "}
            {language[langCode].sendingPage.sendingInfo.attention.change}
          </p>
        )}
      </div>
      <div className="mt-6">
        <p
          className={classNames("text-blue-200 text-xl font-semibold", {
            "text-2xl": phone,
            "text-3xl": !phone,
          })}
        >
          {language[langCode].sendingPage.sendingInfo.incomeAdress}{" "}
          {order && order.to.code}
        </p>
        <div
          className={classNames(
            "flex flex-row font-semibold space-x-2 cursor-pointer",
            {
              "text-xl items-start": phone,
              "text-2xl items-center": !phone,
            }
          )}
          onClick={() =>
            handleClickCopy(order && order.to.address, setAddressRecCopied)
          }
        >
          <p
            className={classNames("break-all", {
              "text-[#95FF54]": isAddressRecCopied,
            })}
          >
            {order && order.to.address}
          </p>
          <img
            className="cursor-pointer"
            src={squares}
            alt={language[langCode].sendingPage.sendingInfo.imgAlt}
          />
        </div>
      </div>

      {/* MEMO */}
      {order.to.tag && (
        <div className="mt-2">
          <p
            className={classNames("text-blue-200 text-xl font-semibold", {
              "text-2xl": phone,
              "text-3xl": !phone,
            })}
          >
            {order.to.tagName}
          </p>
          <div
            className={classNames(
              "flex flex-row font-semibold space-x-2 cursor-pointer",
              {
                "text-xl items-start": phone,
                "text-2xl items-center": !phone,
              }
            )}
            onClick={() => handleClickCopy(order.to.tag, setToTagMemoCopied)}
          >
            <p
              className={classNames("break-all", {
                "text-[#95FF54]": isToTagMemoCopied,
              })}
            >
              {order.to.tag}
            </p>
            <img
              src={squares}
              alt={language[langCode].sendingPage.sendingInfo.imgAlt}
            />
          </div>
        </div>
      )}
    </div>
  );
};
