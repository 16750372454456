import { useMediaQuery } from "@uidotdev/usehooks";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { language } from "../utils/constants";
import { useSelector } from "react-redux";

export const Navbar = () => {
  const iphone = useMediaQuery(
    "only screen and (min-width : 320px) and (max-width : 744px)"
  );

  const classLink = classNames("", { " mb-3 last:mb-0": iphone });

  const langCode = useSelector((state) => state.language);

  return (
    <nav
      className={classNames("flex text-xl font-semibold", {
        "justify-between items-center max-w-sm container": !iphone,
        "flex-col text-end": iphone,
      })}
    >
      <NavLink
        className={({ isActive, isPending }) =>
          isActive ? `text-blue-200 ${classLink}` : classLink
        }
        to={"/about"}
      >
        {language[langCode].navbar.aboutUs}
      </NavLink>
      <NavLink
        className={({ isActive, isPending }) =>
          isActive ? `text-blue-200 ${classLink}` : classLink
        }
        to={"/blog"}
      >
        {language[langCode].navbar.blog}
      </NavLink>
      <NavLink
        className={({ isActive, isPending }) =>
          isActive ? `text-blue-200 ${classLink}` : classLink
        }
        to={"/faq"}
      >
        {language[langCode].navbar.faq}
      </NavLink>
      {/* <NavLink className={({ isActive, isPending }) => isActive ? `text-blue-200 ${classLink}` : classLink} to={"/api"}>API</NavLink> */}
      <NavLink
        className={({ isActive, isPending }) =>
          isActive ? `text-blue-200 ${classLink}` : classLink
        }
        to={"/support"}
      >
        {language[langCode].navbar.support}
      </NavLink>
    </nav>
  );
};
