import { NavLink } from "react-router-dom";

// img
import personal from "../../images/account-icons/personal.svg";
import history from "../../images/account-icons/history-change.svg";
import adressBook from "../../images/account-icons/adress-book.svg";
import classNames from "classnames";
import partner from "../../images/account-icons/partner.svg";
import moneyBack from "../../images/account-icons/money-back.svg";
import apiManagement from "../../images/account-icons/api-management.svg";
import exit from "../../images/account-icons/exit.svg";

import { useSelector } from "react-redux";
import { language } from "../../utils/constants";

export const Sidebar = () => {
  const langCode = useSelector((state) => state.language);

  const menuItems = [
    {
      name: language[langCode].accountMenu.sidebar.personal,
      img: personal,
      path: "",
    },
    {
      name: language[langCode].accountMenu.sidebar.history,
      img: history,
      path: "history",
    },
    {
      name: language[langCode].accountMenu.sidebar.adressBook,
      img: adressBook,
      path: "adress-book",
    },
    {
      name: language[langCode].accountMenu.sidebar.partner,
      img: partner,
      path: "partner",
    },
    {
      name: language[langCode].accountMenu.sidebar.moneyBack,
      img: moneyBack,
      path: "money-back",
    },
    {
      name: language[langCode].accountMenu.sidebar.apiManagement,
      img: apiManagement,
      path: "api-management",
    },
    {
      name: language[langCode].accountMenu.sidebar.exit,
      img: exit,
      path: "exit",
    },
  ];

  return (
    <div className="pr-14 w-[254px]">
      <ul className="flex flex-col whitespace-nowrap">
        {menuItems.map((item, index) => {
          return (
            <li key={index} className="text-base font-normal mb-4">
              <NavLink className="flex" to={item.path}>
                <img
                  className={classNames("text-transparent mr-1 w-6 h-6")}
                  src={item.img}
                  alt={item.path}
                />
                <span>{item.name}</span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
